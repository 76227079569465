<script>
	import AwardsCarouselBlock from "$components/home/AwardsCarouselBlock.svelte";
	import SoftwareworldAwardIcon from "$icons/softwareworld-badge.svelte";
	import TechReviewAwardIcon from "$icons/techreview-badge.svelte";
	import ClutchAwardIcon from "$icons/clutch-badge.svelte";
	import GoodfirmsAwardIcon from "$icons/goodfirms-badge.svelte";
</script>

<div class="block-bg">
	<div class="container">
		<div class="awards flex-row align-center">
			<a
				href="https://clutch.co/profile/exoft"
				target="_blank"
				rel="noopener noreferrer nofollow">
				<ClutchAwardIcon />
			</a>
			
			<a
				href="https://selectedfirms.co/agency/exoft"
				target="_blank"
				rel="noopener noreferrer nofollow">
				<img alt="selected firms" src="/selected-firms-badge.webp" height="100%" />
			</a>
			
			<a
				href="https://techreviewer.co/companies/exoft"
				target="_blank"
				rel="noopener noreferrer nofollow">
				<TechReviewAwardIcon />
			</a>
			
			<a href="https://www.softwareworld.co/" target="_blank" rel="noopener noreferrer nofollow">
				<SoftwareworldAwardIcon />
			</a>
			
			<a
				href="https://www.goodfirms.co/company/exoft"
				target="_blank"
				rel="noopener noreferrer nofollow">
				<GoodfirmsAwardIcon />
			</a>
		</div>
		
		<div id="awards-mobile">
			<AwardsCarouselBlock />
		</div>
	</div>
</div>

<style lang="scss">
  @import '$styles/vars.scss';


    .block-bg {
      background: $main-black;
    }

    #awards-mobile {
      display: none;
    }

    .awards {
      padding: 0;
      width: 100%;
      max-width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;

      a {
        height: 150px;
      }
    }

  @media (min-width: $portrait-phone-size) and (max-width: $tablet-size) {
    .awards {
        gap: 10px;
        justify-content: center;
        a {
          height: 100px;
        }
      }
    }

  @media (max-width: $portrait-phone-size) {
      .awards {
        display: none;
      }

      #awards-mobile {
        display: block;
      }
  }

</style>
