<svg width="150" height="100%" viewBox="0 0 150 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4141 39.1891L75 2.47774L138.586 39.1891V112.612L75 149.323L11.4141 112.612V39.1891Z" stroke="white"/>
<path d="M16.6865 42.2333L75 8.56604L133.313 42.2333V109.568L75 143.235L16.6865 109.568V42.2333Z" stroke="white" stroke-width="3"/>
<path d="M73.3324 40.1523V36.4895L74.2107 36.9332C74.8557 37.2591 75.6263 37.2117 76.2265 36.8092L77.0356 36.2666V40.1523H78.2308C78.286 40.1523 78.3308 40.1971 78.3308 40.2523V41.2049H79.7873C79.8426 41.2049 79.8873 41.2496 79.8873 41.3049V42.6813H80.7477C80.8029 42.6813 80.8477 42.726 80.8477 42.7813V43.8498H69.3986L69.3986 42.7813C69.3986 42.726 69.4433 42.6813 69.4986 42.6813H70.4437V41.3049C70.4437 41.2496 70.4885 41.2049 70.5437 41.2049H71.9773V40.2523C71.9773 40.1971 72.0221 40.1523 72.0773 40.1523H73.3324Z" stroke="white"/>
<path d="M69.3758 24.9381L66.2461 24.9377C65.6138 24.9376 65.1402 25.5169 65.2659 26.1365L66.6484 32.9495C66.7429 33.4157 67.1528 33.7507 67.6284 33.7507H71.0493" stroke="white"/>
<path d="M80.6244 24.9381L83.7541 24.9377C84.3863 24.9376 84.86 25.5169 84.7343 26.1365L83.3518 32.9495C83.2572 33.4157 82.8474 33.7507 82.3718 33.7507H78.9508" stroke="white"/>
<path d="M79.8465 23.4326H70.3794C69.8866 23.4326 69.4644 23.7923 69.4077 24.2818C68.5758 31.4599 71.8913 35.2469 74.2807 36.7669C74.7911 37.0915 75.44 37.0846 75.9552 36.7677C80.9128 33.7189 81.3816 27.2304 80.7575 24.1387C80.6713 23.7117 80.2822 23.4326 79.8465 23.4326Z" stroke="white"/>
<path d="M47.5318 33.2418C47.6518 33.1239 47.8549 33.1978 47.871 33.3652L48.1371 36.1241C48.1436 36.1914 48.1838 36.251 48.2438 36.2822L50.702 37.5624C50.8513 37.6401 50.8437 37.8561 50.6894 37.9232L48.1479 39.0288C48.0858 39.0558 48.0416 39.1124 48.0304 39.1792L47.5725 41.9127C47.5447 42.0786 47.337 42.1382 47.2255 42.0122L45.3886 39.9367C45.3438 39.886 45.2763 39.8614 45.2093 39.8714L42.4681 40.2807C42.3017 40.3055 42.1809 40.1263 42.2662 39.9814L43.6725 37.593C43.7069 37.5347 43.7094 37.4629 43.6792 37.4023L42.4429 34.9217C42.3679 34.7712 42.5009 34.6009 42.6652 34.6372L45.3712 35.2367C45.4373 35.2513 45.5063 35.2315 45.5546 35.1841L47.5318 33.2418Z" fill="white"/>
<path d="M55.3114 29.0098C55.4485 28.9123 55.6374 29.0173 55.6269 29.1852L55.496 31.2929C55.4918 31.3605 55.522 31.4256 55.5764 31.4659L57.2723 32.7242C57.4074 32.8245 57.366 33.0366 57.2031 33.0785L55.1581 33.6053C55.0925 33.6222 55.0399 33.6711 55.0184 33.7353L54.3457 35.737C54.2921 35.8965 54.0776 35.9226 53.9873 35.7806L52.8544 33.9985C52.8181 33.9414 52.7553 33.9065 52.6876 33.9058L50.576 33.8847C50.4078 33.883 50.3167 33.687 50.4238 33.5573L51.7686 31.9291C51.8117 31.8769 51.8255 31.8064 51.8052 31.7418L51.1728 29.727C51.1224 29.5665 51.2806 29.4193 51.4371 29.4811L53.4012 30.257C53.4641 30.2818 53.5354 30.2732 53.5906 30.2339L55.3114 29.0098Z" fill="white"/>
<path d="M42.9955 42.7761C43.0921 42.6384 43.3053 42.6742 43.3516 42.8359L43.9328 44.8661C43.9514 44.9312 44.0017 44.9825 44.0664 45.0023L46.0854 45.6213C46.2463 45.6706 46.2781 45.8843 46.1386 45.9783L44.3874 47.1585C44.3312 47.1963 44.298 47.26 44.2992 47.3277L44.3344 49.4391C44.3372 49.6073 44.1438 49.7036 44.0112 49.6L42.3477 48.2992C42.2944 48.2575 42.2236 48.2456 42.1595 48.2676L40.1623 48.9536C40.0032 49.0082 39.8518 48.854 39.9094 48.6959L40.6325 46.7118C40.6557 46.6482 40.6452 46.5772 40.6045 46.5231L39.3348 44.8356C39.2337 44.7012 39.3336 44.5096 39.5017 44.5155L41.6122 44.5901C41.6798 44.5925 41.7441 44.5605 41.783 44.5051L42.9955 42.7761Z" fill="white"/>
<path d="M102.468 33.2418C102.348 33.1239 102.145 33.1978 102.129 33.3652L101.863 36.1241C101.857 36.1914 101.816 36.251 101.756 36.2822L99.2981 37.5624C99.1489 37.6401 99.1565 37.8561 99.3107 37.9232L101.852 39.0288C101.914 39.0558 101.959 39.1124 101.97 39.1792L102.428 41.9127C102.455 42.0786 102.663 42.1382 102.775 42.0122L104.612 39.9367C104.656 39.886 104.724 39.8614 104.791 39.8714L107.532 40.2807C107.698 40.3055 107.819 40.1263 107.734 39.9814L106.328 37.593C106.293 37.5347 106.291 37.4629 106.321 37.4023L107.557 34.9217C107.632 34.7712 107.499 34.6009 107.335 34.6372L104.629 35.2367C104.563 35.2513 104.494 35.2315 104.446 35.1841L102.468 33.2418Z" fill="white"/>
<path d="M94.6887 29.0098C94.5517 28.9123 94.3628 29.0173 94.3732 29.1852L94.5041 31.2929C94.5083 31.3605 94.4781 31.4256 94.4237 31.4659L92.7278 32.7242C92.5927 32.8245 92.6342 33.0366 92.7971 33.0785L94.8421 33.6053C94.9076 33.6222 94.9602 33.6711 94.9818 33.7353L95.6544 35.737C95.708 35.8965 95.9225 35.9226 96.0128 35.7806L97.1457 33.9985C97.182 33.9414 97.2448 33.9065 97.3125 33.9058L99.4241 33.8847C99.5924 33.883 99.6835 33.687 99.5763 33.5573L98.2315 31.9291C98.1884 31.8769 98.1746 31.8064 98.1949 31.7418L98.8273 29.727C98.8777 29.5665 98.7195 29.4193 98.563 29.4811L96.5989 30.257C96.536 30.2818 96.4647 30.2732 96.4095 30.2339L94.6887 29.0098Z" fill="white"/>
<path d="M107.005 42.7761C106.908 42.6384 106.695 42.6742 106.649 42.8359L106.067 44.8661C106.049 44.9312 105.998 44.9825 105.934 45.0023L103.915 45.6213C103.754 45.6706 103.722 45.8843 103.862 45.9783L105.613 47.1585C105.669 47.1963 105.702 47.26 105.701 47.3277L105.666 49.4391C105.663 49.6073 105.856 49.7036 105.989 49.6L107.652 48.2992C107.706 48.2575 107.777 48.2456 107.841 48.2676L109.838 48.9536C109.997 49.0082 110.148 48.854 110.091 48.6959L109.368 46.7118C109.344 46.6482 109.355 46.5772 109.396 46.5231L110.665 44.8356C110.766 44.7012 110.667 44.5096 110.498 44.5155L108.388 44.5901C108.32 44.5925 108.256 44.5605 108.217 44.5051L107.005 42.7761Z" fill="white"/>
<path d="M45.8872 101.958V91.778H44.0472V90.138H50.0472V91.778H48.2072V101.958H45.8872Z" fill="white"/>
<path d="M54.0776 102.198C53.4243 102.198 52.851 102.118 52.3576 101.958C51.8643 101.811 51.4776 101.498 51.1976 101.018C50.931 100.525 50.7976 99.798 50.7976 98.838V92.638C50.7976 91.9313 50.931 91.3847 51.1976 90.998C51.4776 90.6113 51.8643 90.3447 52.3576 90.198C52.8643 90.038 53.4443 89.958 54.0976 89.958C54.7376 89.958 55.3043 90.038 55.7976 90.198C56.3043 90.358 56.6976 90.6313 56.9776 91.018C57.271 91.4047 57.4176 91.9447 57.4176 92.638V98.818C57.4176 99.7913 57.271 100.525 56.9776 101.018C56.6976 101.498 56.3043 101.811 55.7976 101.958C55.3043 102.118 54.731 102.198 54.0776 102.198ZM54.0776 100.838C54.2776 100.838 54.451 100.805 54.5976 100.738C54.7576 100.671 54.8776 100.558 54.9576 100.398C55.051 100.225 55.0976 99.9913 55.0976 99.698V92.538C55.0976 92.2447 55.051 92.018 54.9576 91.858C54.8776 91.6847 54.7576 91.5647 54.5976 91.498C54.451 91.4313 54.2776 91.398 54.0776 91.398C53.8776 91.398 53.7043 91.4313 53.5576 91.498C53.411 91.5647 53.291 91.6847 53.1976 91.858C53.1176 92.018 53.0776 92.2447 53.0776 92.538V99.698C53.0776 99.9913 53.1176 100.225 53.1976 100.398C53.291 100.558 53.411 100.671 53.5576 100.738C53.7043 100.805 53.8776 100.838 54.0776 100.838Z" fill="white"/>
<path d="M58.965 101.958V90.138H63.165C63.9517 90.138 64.545 90.318 64.945 90.678C65.3583 91.0247 65.565 91.5113 65.565 92.138V94.178C65.565 94.7113 65.4983 95.2113 65.365 95.678C65.2317 96.1313 64.9917 96.498 64.645 96.778C64.2983 97.058 63.7983 97.198 63.145 97.198H61.385V101.958H58.965ZM61.385 95.918H62.285C62.605 95.918 62.845 95.7647 63.005 95.458C63.165 95.1513 63.245 94.6447 63.245 93.938V93.138C63.245 92.5113 63.1783 92.0647 63.045 91.798C62.9117 91.5313 62.7183 91.398 62.465 91.398H61.385V95.918Z" fill="white"/>
<path d="M70.1369 101.958V90.138H74.4569C75.2435 90.138 75.8369 90.318 76.2369 90.678C76.6369 91.0247 76.8369 91.5113 76.8369 92.138V93.978C76.8369 94.6047 76.6702 95.1313 76.3369 95.558C76.0035 95.9847 75.4835 96.2313 74.7769 96.298C75.2302 96.3647 75.5502 96.518 75.7369 96.758C75.9369 96.9847 76.1102 97.358 76.2569 97.878L77.3769 101.958H74.7969L73.9369 98.038C73.8569 97.718 73.7635 97.4513 73.6569 97.238C73.5635 97.0113 73.4235 96.898 73.2369 96.898H72.5569V101.958H70.1369ZM72.5569 95.278H73.5169C73.8902 95.278 74.1569 95.178 74.3169 94.978C74.4769 94.7647 74.5569 94.4313 74.5569 93.978V92.558C74.5569 92.1313 74.4835 91.8313 74.3369 91.658C74.1902 91.4847 73.9635 91.398 73.6569 91.398H72.5569V95.278Z" fill="white"/>
<path d="M77.8553 101.958L79.9753 90.138H83.2953L85.3753 101.958H82.7753L82.4153 99.778H80.6953L80.3553 101.958H77.8553ZM80.7353 98.618H82.3953L81.5753 92.198L80.7353 98.618Z" fill="white"/>
<path d="M86.9419 101.958V91.778H85.1019V90.138H91.1019V91.778H89.2619V101.958H86.9419Z" fill="white"/>
<path d="M92.1291 101.958V90.138H97.5691V91.778H94.5491V94.938H97.1091V96.758H94.5491V100.358H97.5691V101.958H92.1291Z" fill="white"/>
<path d="M98.8087 101.958V90.138H102.949C103.509 90.138 103.969 90.218 104.329 90.378C104.702 90.538 104.975 90.7847 105.149 91.118C105.335 91.438 105.429 91.8447 105.429 92.338V99.418C105.429 100.071 105.315 100.585 105.089 100.958C104.862 101.331 104.535 101.591 104.109 101.738C103.695 101.885 103.202 101.958 102.629 101.958H98.8087ZM101.229 100.758H102.249C102.515 100.758 102.722 100.678 102.869 100.518C103.029 100.358 103.109 100.058 103.109 99.618V92.598C103.109 92.158 103.029 91.858 102.869 91.698C102.722 91.538 102.515 91.458 102.249 91.458H101.229V100.758Z" fill="white"/>
<path d="M59.7133 123.825C59.7133 123.425 59.7133 123.025 59.7133 122.625C59.7133 122.225 59.7133 121.825 59.7133 121.425C59.7133 120.705 59.8 120.139 59.9733 119.725C60.16 119.299 60.4333 118.952 60.7933 118.685C61.1667 118.405 61.64 118.125 62.2133 117.845C62.68 117.619 63.0467 117.392 63.3133 117.165C63.58 116.939 63.7733 116.672 63.8933 116.365C64.0267 116.045 64.0933 115.639 64.0933 115.145C64.1067 114.652 64.04 114.272 63.8933 114.005C63.7467 113.739 63.4867 113.605 63.1133 113.605C62.7533 113.605 62.4933 113.765 62.3333 114.085C62.1867 114.392 62.1133 114.852 62.1133 115.465V116.285H59.7933V114.805C59.7933 113.845 60.0733 113.132 60.6333 112.665C61.2067 112.185 62.0467 111.945 63.1533 111.945C63.9933 111.945 64.66 112.059 65.1533 112.285C65.6467 112.512 65.9933 112.865 66.1933 113.345C66.4067 113.825 66.5133 114.459 66.5133 115.245C66.5133 116.179 66.36 116.932 66.0533 117.505C65.7467 118.079 65.2267 118.565 64.4933 118.965C64.1733 119.125 63.8733 119.285 63.5933 119.445C63.3133 119.605 63.0667 119.779 62.8533 119.965C62.6533 120.152 62.5067 120.392 62.4133 120.685C62.3333 120.859 62.2667 121.052 62.2133 121.265C62.16 121.465 62.1333 121.685 62.1333 121.925H66.5533V123.825H59.7133Z" fill="white"/>
<path d="M70.9072 124.085C70.2539 124.072 69.6739 123.939 69.1672 123.685C68.6739 123.419 68.2872 122.999 68.0072 122.425C67.7272 121.839 67.5872 121.065 67.5872 120.105V115.305C67.5872 114.132 67.8939 113.265 68.5072 112.705C69.1339 112.145 69.9472 111.865 70.9472 111.865C71.9072 111.865 72.6872 112.145 73.2872 112.705C73.9006 113.265 74.2072 114.132 74.2072 115.305V120.085C74.2072 121.072 74.0672 121.859 73.7872 122.445C73.5206 123.032 73.1339 123.452 72.6272 123.705C72.1339 123.959 71.5606 124.085 70.9072 124.085ZM70.9072 122.705C71.2006 122.705 71.4406 122.592 71.6272 122.365C71.8139 122.125 71.9072 121.772 71.9072 121.305V114.665C71.9072 114.199 71.8072 113.852 71.6072 113.625C71.4206 113.385 71.1872 113.265 70.9072 113.265C70.6272 113.265 70.3872 113.385 70.1872 113.625C70.0006 113.852 69.9072 114.199 69.9072 114.665V121.305C69.9072 121.772 70.0006 122.125 70.1872 122.365C70.3872 122.592 70.6272 122.705 70.9072 122.705Z" fill="white"/>
<path d="M75.3188 123.825C75.3188 123.425 75.3188 123.025 75.3188 122.625C75.3188 122.225 75.3188 121.825 75.3188 121.425C75.3188 120.705 75.4055 120.139 75.5788 119.725C75.7655 119.299 76.0388 118.952 76.3988 118.685C76.7721 118.405 77.2455 118.125 77.8188 117.845C78.2855 117.619 78.6521 117.392 78.9188 117.165C79.1855 116.939 79.3788 116.672 79.4988 116.365C79.6321 116.045 79.6988 115.639 79.6988 115.145C79.7121 114.652 79.6455 114.272 79.4988 114.005C79.3521 113.739 79.0921 113.605 78.7188 113.605C78.3588 113.605 78.0988 113.765 77.9388 114.085C77.7921 114.392 77.7188 114.852 77.7188 115.465V116.285H75.3988V114.805C75.3988 113.845 75.6788 113.132 76.2388 112.665C76.8121 112.185 77.6521 111.945 78.7588 111.945C79.5988 111.945 80.2655 112.059 80.7588 112.285C81.2521 112.512 81.5988 112.865 81.7988 113.345C82.0121 113.825 82.1188 114.459 82.1188 115.245C82.1188 116.179 81.9655 116.932 81.6588 117.505C81.3521 118.079 80.8321 118.565 80.0988 118.965C79.7788 119.125 79.4788 119.285 79.1988 119.445C78.9188 119.605 78.6721 119.779 78.4588 119.965C78.2588 120.152 78.1121 120.392 78.0188 120.685C77.9388 120.859 77.8721 121.052 77.8188 121.265C77.7655 121.465 77.7388 121.685 77.7388 121.925H82.1588V123.825H75.3188Z" fill="white"/>
<path d="M86.3927 123.965C85.6194 123.965 84.9794 123.852 84.4727 123.625C83.966 123.385 83.5927 123.052 83.3527 122.625C83.1127 122.185 82.9927 121.659 82.9927 121.045V119.685H85.3127V120.425C85.3127 121.025 85.3927 121.485 85.5527 121.805C85.7127 122.125 85.9794 122.292 86.3527 122.305C86.6994 122.305 86.9527 122.165 87.1127 121.885C87.286 121.605 87.3727 121.245 87.3727 120.805V119.665C87.3727 119.225 87.226 118.912 86.9327 118.725C86.6394 118.539 86.206 118.439 85.6327 118.425H84.6927V117.145H85.6527C85.9194 117.145 86.1594 117.112 86.3727 117.045C86.586 116.979 86.766 116.885 86.9127 116.765C87.0727 116.632 87.186 116.492 87.2527 116.345C87.3194 116.185 87.3527 116.019 87.3527 115.845V115.025C87.3527 114.639 87.2794 114.305 87.1327 114.025C86.986 113.745 86.726 113.605 86.3527 113.605C85.966 113.605 85.6994 113.765 85.5527 114.085C85.4194 114.405 85.3527 114.845 85.3527 115.405V115.885H83.0127V114.785C83.0127 113.852 83.286 113.145 83.8327 112.665C84.3927 112.185 85.246 111.945 86.3927 111.945C87.5927 111.945 88.4527 112.165 88.9727 112.605C89.506 113.045 89.7727 113.705 89.7727 114.585V115.485C89.7727 116.165 89.5994 116.712 89.2527 117.125C88.9194 117.539 88.426 117.785 87.7727 117.865C88.426 117.905 88.926 118.132 89.2727 118.545C89.6194 118.959 89.7927 119.505 89.7927 120.185V121.045C89.7927 121.712 89.6794 122.265 89.4527 122.705C89.226 123.132 88.8594 123.452 88.3527 123.665C87.8594 123.865 87.206 123.965 86.3927 123.965Z" fill="white"/>
<path d="M26.96 74.9115C26.3067 74.9115 25.74 74.8115 25.26 74.6115C24.78 74.4115 24.4134 74.0582 24.16 73.5515C23.92 73.0448 23.8 72.3315 23.8 71.4115V70.7315C24.12 70.7315 24.4467 70.7315 24.78 70.7315C25.1134 70.7315 25.44 70.7315 25.76 70.7315V71.5715C25.76 72.1315 25.8134 72.5648 25.92 72.8715C26.0267 73.1782 26.1734 73.3915 26.36 73.5115C26.5467 73.6182 26.76 73.6715 27 73.6715C27.3867 73.6715 27.6934 73.5448 27.92 73.2915C28.16 73.0248 28.28 72.5315 28.28 71.8115C28.28 71.2782 28.2 70.8782 28.04 70.6115C27.88 70.3448 27.6267 70.1315 27.28 69.9715C26.9467 69.7982 26.5134 69.6048 25.98 69.3915C25.54 69.1915 25.16 68.9582 24.84 68.6915C24.52 68.4248 24.28 68.0848 24.12 67.6715C23.96 67.2582 23.88 66.7448 23.88 66.1315C23.88 65.3848 23.9734 64.7782 24.16 64.3115C24.36 63.8315 24.6934 63.4848 25.16 63.2715C25.6267 63.0448 26.2534 62.9315 27.04 62.9315C28.0534 62.9315 28.8267 63.1648 29.36 63.6315C29.8934 64.0848 30.16 64.7515 30.16 65.6315V67.0115C29.8534 67.0115 29.54 67.0115 29.22 67.0115C28.9134 67.0115 28.6 67.0115 28.28 67.0115V66.1915C28.28 65.5782 28.18 65.1382 27.98 64.8715C27.7934 64.5915 27.4867 64.4515 27.06 64.4515C26.62 64.4515 26.3 64.5848 26.1 64.8515C25.9134 65.1048 25.82 65.5115 25.82 66.0715C25.82 66.5515 25.9134 66.9248 26.1 67.1915C26.2867 67.4448 26.5267 67.6448 26.82 67.7915C27.1267 67.9382 27.44 68.0715 27.76 68.1915C28.36 68.4182 28.8467 68.6515 29.22 68.8915C29.5934 69.1315 29.8667 69.4515 30.04 69.8515C30.2134 70.2515 30.3 70.8182 30.3 71.5515C30.3 72.3915 30.16 73.0582 29.88 73.5515C29.6134 74.0315 29.2267 74.3782 28.72 74.5915C28.2267 74.8048 27.64 74.9115 26.96 74.9115Z" fill="white"/>
<path d="M34.8758 75.0115C34.2492 75.0115 33.6958 74.9382 33.2158 74.7915C32.7492 74.6448 32.3825 74.3448 32.1158 73.8915C31.8492 73.4248 31.7158 72.7315 31.7158 71.8115V65.4315C31.7158 64.7115 31.8492 64.1648 32.1158 63.7915C32.3825 63.4048 32.7558 63.1382 33.2358 62.9915C33.7158 62.8448 34.2692 62.7715 34.8958 62.7715C35.5225 62.7715 36.0692 62.8515 36.5358 63.0115C37.0158 63.1582 37.3958 63.4248 37.6758 63.8115C37.9558 64.1848 38.0958 64.7248 38.0958 65.4315V71.7915C38.0958 72.7382 37.9558 73.4382 37.6758 73.8915C37.3958 74.3448 37.0158 74.6448 36.5358 74.7915C36.0558 74.9382 35.5025 75.0115 34.8758 75.0115ZM34.8758 73.8715C35.1292 73.8715 35.3558 73.8382 35.5558 73.7715C35.7558 73.6915 35.9092 73.5515 36.0158 73.3515C36.1225 73.1515 36.1758 72.8582 36.1758 72.4715V65.3715C36.1758 64.9982 36.1225 64.7115 36.0158 64.5115C35.9092 64.2982 35.7558 64.1582 35.5558 64.0915C35.3558 64.0115 35.1292 63.9715 34.8758 63.9715C34.6225 63.9715 34.3958 64.0115 34.1958 64.0915C34.0092 64.1582 33.8625 64.2982 33.7558 64.5115C33.6492 64.7115 33.5958 64.9982 33.5958 65.3715V72.4715C33.5958 72.8582 33.6492 73.1515 33.7558 73.3515C33.8625 73.5515 34.0092 73.6915 34.1958 73.7715C34.3958 73.8382 34.6225 73.8715 34.8758 73.8715Z" fill="white"/>
<path d="M39.9422 74.7715V62.9515H45.2622V64.3115H41.9422V67.8915H44.6822V69.3915H41.9422V74.7715H39.9422Z" fill="white"/>
<path d="M47.6433 74.7715V64.3115H45.7633V62.9515H51.4833V64.3115H49.5633V74.7715H47.6433Z" fill="white"/>
<path d="M53.8166 74.7715L51.9966 62.9515H53.9566L55.2166 72.2515L56.6766 62.9515H58.2566L59.6766 72.2515L60.9766 62.9515H62.9366L61.1166 74.7715H58.8966L57.4566 67.0915L56.0566 74.7715H53.8166Z" fill="white"/>
<path d="M62.8732 74.7715L65.1732 62.9515H67.8532L70.1132 74.7715H67.9932L67.5132 72.3715H65.3532L64.9132 74.7715H62.8732ZM65.3932 71.3915H67.4932L66.4532 64.8115L65.3932 71.3915Z" fill="white"/>
<path d="M71.329 74.7715V62.9515H75.409C76.1556 62.9515 76.729 63.1182 77.129 63.4515C77.5423 63.7848 77.749 64.2782 77.749 64.9315V66.8115C77.749 67.4648 77.569 67.9982 77.209 68.4115C76.849 68.8115 76.3156 69.0315 75.609 69.0715C76.0623 69.1115 76.3823 69.2582 76.569 69.5115C76.769 69.7648 76.949 70.1582 77.109 70.6915L78.249 74.7715H76.129L75.189 70.8315C75.0956 70.4982 74.989 70.2048 74.869 69.9515C74.749 69.6982 74.5556 69.5715 74.289 69.5715H73.309V74.7715H71.329ZM73.309 68.2315H74.589C75.0556 68.2315 75.3823 68.1115 75.569 67.8715C75.769 67.6315 75.869 67.2648 75.869 66.7715V65.2715C75.869 64.8315 75.7756 64.5115 75.589 64.3115C75.4156 64.1115 75.1423 64.0115 74.769 64.0115H73.309V68.2315Z" fill="white"/>
<path d="M79.6688 74.7715V62.9515H84.9888V64.3115H81.6688V67.8915H84.4088V69.3915H81.6688V73.4515H84.9888V74.7715H79.6688Z" fill="white"/>
<path d="M87.5471 74.7715L85.7271 62.9515H87.6871L88.9471 72.2515L90.4071 62.9515H91.9871L93.4071 72.2515L94.7071 62.9515H96.6671L94.8471 74.7715H92.6271L91.1871 67.0915L89.7871 74.7715H87.5471Z" fill="white"/>
<path d="M100.833 75.0115C100.206 75.0115 99.6529 74.9382 99.1729 74.7915C98.7062 74.6448 98.3395 74.3448 98.0729 73.8915C97.8062 73.4248 97.6729 72.7315 97.6729 71.8115V65.4315C97.6729 64.7115 97.8062 64.1648 98.0729 63.7915C98.3395 63.4048 98.7129 63.1382 99.1929 62.9915C99.6729 62.8448 100.226 62.7715 100.853 62.7715C101.48 62.7715 102.026 62.8515 102.493 63.0115C102.973 63.1582 103.353 63.4248 103.633 63.8115C103.913 64.1848 104.053 64.7248 104.053 65.4315V71.7915C104.053 72.7382 103.913 73.4382 103.633 73.8915C103.353 74.3448 102.973 74.6448 102.493 74.7915C102.013 74.9382 101.46 75.0115 100.833 75.0115ZM100.833 73.8715C101.086 73.8715 101.313 73.8382 101.513 73.7715C101.713 73.6915 101.866 73.5515 101.973 73.3515C102.08 73.1515 102.133 72.8582 102.133 72.4715V65.3715C102.133 64.9982 102.08 64.7115 101.973 64.5115C101.866 64.2982 101.713 64.1582 101.513 64.0915C101.313 64.0115 101.086 63.9715 100.833 63.9715C100.58 63.9715 100.353 64.0115 100.153 64.0915C99.9662 64.1582 99.8195 64.2982 99.7129 64.5115C99.6062 64.7115 99.5529 64.9982 99.5529 65.3715V72.4715C99.5529 72.8582 99.6062 73.1515 99.7129 73.3515C99.8195 73.5515 99.9662 73.6915 100.153 73.7715C100.353 73.8382 100.58 73.8715 100.833 73.8715Z" fill="white"/>
<path d="M105.899 74.7715V62.9515H109.979C110.726 62.9515 111.299 63.1182 111.699 63.4515C112.113 63.7848 112.319 64.2782 112.319 64.9315V66.8115C112.319 67.4648 112.139 67.9982 111.779 68.4115C111.419 68.8115 110.886 69.0315 110.179 69.0715C110.633 69.1115 110.953 69.2582 111.139 69.5115C111.339 69.7648 111.519 70.1582 111.679 70.6915L112.819 74.7715H110.699L109.759 70.8315C109.666 70.4982 109.559 70.2048 109.439 69.9515C109.319 69.6982 109.126 69.5715 108.859 69.5715H107.879V74.7715H105.899ZM107.879 68.2315H109.159C109.626 68.2315 109.953 68.1115 110.139 67.8715C110.339 67.6315 110.439 67.2648 110.439 66.7715V65.2715C110.439 64.8315 110.346 64.5115 110.159 64.3115C109.986 64.1115 109.713 64.0115 109.339 64.0115H107.879V68.2315Z" fill="white"/>
<path d="M114.239 74.7715V62.9515H116.119V73.4515H119.059V74.7715H114.239Z" fill="white"/>
<path d="M120.196 74.7715V62.9515H124.116C124.649 62.9515 125.096 63.0315 125.456 63.1915C125.816 63.3515 126.089 63.5982 126.276 63.9315C126.476 64.2648 126.576 64.6982 126.576 65.2315V72.2315C126.576 72.8848 126.463 73.3982 126.236 73.7715C126.009 74.1448 125.689 74.4048 125.276 74.5515C124.876 74.6982 124.403 74.7715 123.856 74.7715H120.196ZM122.176 73.7515H123.556C123.889 73.7515 124.156 73.6582 124.356 73.4715C124.556 73.2715 124.656 72.9048 124.656 72.3715V65.4515C124.656 64.9182 124.556 64.5515 124.356 64.3515C124.156 64.1515 123.889 64.0515 123.556 64.0515H122.176V73.7515Z" fill="white"/>
<rect x="22.1808" y="53.9961" width="45.9886" height="2.69792" fill="white"/>
<rect x="81.8308" y="53.9961" width="45.9886" height="2.69792" fill="white"/>
<path d="M75.1588 50.873L76.3437 53.2922L79.0312 53.1088L77.5287 55.3446L79.0312 57.5804L76.3437 57.397L75.1588 59.8162L73.9738 57.397L71.2863 57.5804L72.7888 55.3446L71.2863 53.1088L73.9738 53.2922L75.1588 50.873Z" fill="white"/>
<rect x="17.5618" y="81.2178" width="114.343" height="2.69792" fill="white"/>
</svg>
