<svg width="150" height="100%" viewBox="0 0 150 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M73.8703 149.9L17.375 128.166V1.90039H130.365V128.166L73.8703 149.9ZM20.3329 126.131L73.8703 146.726L127.408 126.131V4.85826H20.3329V126.131Z" fill="white"/>
<path d="M26.2275 114.465V121.451V121.564L73.8492 139.885L121.471 121.564V121.451V114.465L73.8492 132.786L26.2275 114.465Z" fill="white"/>
<path d="M26.2275 104.556V111.542V111.655L73.8492 129.976L121.471 111.655V111.542V104.556L73.8492 122.877L26.2275 104.556Z" fill="white"/>
<path d="M26.2275 94.6475V101.634V101.746L73.8492 120.067L121.471 101.746V101.634V94.6475L73.8492 112.969L26.2275 94.6475Z" fill="white"/>
<path d="M71.36 15.8315C71.36 15.8315 66.3642 16.4822 66.308 16.6538C66.2517 16.8253 69.9136 20.2859 69.9136 20.2859C69.9136 20.2859 68.9878 25.2404 69.1328 25.344C69.2776 25.4475 73.7027 23.0398 73.7027 23.0398C73.7027 23.0398 78.1394 25.4504 78.2695 25.344C78.3998 25.2375 77.4917 20.2859 77.4917 20.2859C77.4917 20.2859 81.1535 16.8253 81.0973 16.6538C81.0411 16.4822 76.0452 15.8315 76.0452 15.8315C76.0452 15.8315 73.8802 11.2822 73.7027 11.2822C73.5252 11.2822 71.36 15.8315 71.36 15.8315Z" fill="white"/>
<path d="M67.5586 44.2568V46.5077H64.3791V55.973H61.5306V46.5077H58.3834V44.2568H67.5586Z" fill="white"/>
<path d="M77.3287 44.8445C78.2199 45.3491 78.9563 46.0875 79.4584 46.9801C79.972 47.9306 80.2409 48.9938 80.2409 50.0741C80.2409 51.1544 79.972 52.2176 79.4584 53.1681C78.9526 54.0631 78.2135 54.8043 77.3199 55.3125C76.4043 55.8169 75.3762 56.0813 74.331 56.0813C73.2856 56.0813 72.2575 55.8169 71.3419 55.3125C70.4489 54.8034 69.7099 54.0625 69.2034 53.1681C68.6872 52.2185 68.4168 51.1548 68.4168 50.0741C68.4168 48.9932 68.6872 47.9297 69.2034 46.9801C69.7104 46.0882 70.4493 45.3503 71.3419 44.8445C72.2602 44.3386 73.2914 44.0732 74.3398 44.0732C75.3882 44.0732 76.4194 44.3386 77.3376 44.8445H77.3287ZM72.1111 47.6102C71.5648 48.2018 71.2917 49.021 71.2917 50.0682C71.2438 50.9599 71.5367 51.8364 72.1111 52.5202C72.697 53.104 73.4905 53.4318 74.3176 53.4318C75.1448 53.4318 75.9382 53.104 76.5241 52.5202C77.0587 51.8145 77.348 50.9535 77.348 50.0682C77.348 49.1829 77.0587 48.3219 76.5241 47.6162C75.9346 47.0301 75.137 46.7012 74.3058 46.7012C73.4745 46.7012 72.677 47.0301 72.0874 47.6162L72.1111 47.6102Z" fill="white"/>
<path d="M84.6527 51.8586V55.967H81.8308V44.2568H86.4215C87.8018 44.2568 88.8617 44.6019 89.6013 45.292C89.9694 45.6538 90.257 46.0892 90.4451 46.5699C90.6332 47.0506 90.7177 47.5658 90.6928 48.0814C90.7051 48.7619 90.5367 49.4334 90.2046 50.0277C89.8667 50.6101 89.3618 51.0779 88.7553 51.3704C88.0268 51.7151 87.2271 51.8823 86.4215 51.8586H84.6527ZM87.7998 48.0814C87.7998 47.0559 87.2309 46.5432 86.0931 46.5432H84.6527V49.5898H86.0931C87.2329 49.5898 87.8018 49.087 87.7998 48.0814Z" fill="white"/>
<path d="M54.9452 70.1735C54.7317 70.5189 54.4238 70.796 54.0578 70.9721C53.6103 71.1812 53.1199 71.2826 52.6262 71.2679C51.881 71.2978 51.1468 71.0804 50.5379 70.6497C50.2696 70.4477 50.0504 70.1878 49.8968 69.8892C49.7431 69.5905 49.6588 69.261 49.6506 68.9252H51.588C51.601 69.1777 51.7062 69.4167 51.8837 69.5967C52.0653 69.7615 52.3043 69.8485 52.5493 69.8392C52.7522 69.8512 52.9518 69.7833 53.1053 69.6499C53.1726 69.5834 53.2253 69.5034 53.2599 69.4153C53.2945 69.3272 53.3105 69.2328 53.3065 69.1382C53.3092 69.0499 53.2942 68.9619 53.2622 68.8796C53.2301 68.7974 53.1819 68.7222 53.1201 68.659C52.9877 68.5248 52.8298 68.4182 52.6558 68.3455C52.4724 68.2626 52.2181 68.1651 51.8897 68.0498C51.4911 67.9169 51.1018 67.7578 50.7242 67.5735C50.413 67.4142 50.1458 67.1805 49.9464 66.8931C49.7307 66.541 49.6178 66.1354 49.621 65.7222C49.6242 65.3093 49.743 64.9054 49.9642 64.5565C50.1997 64.2216 50.5261 63.961 50.9047 63.8052C51.3414 63.6244 51.8106 63.5358 52.283 63.5449C52.9971 63.5049 53.7026 63.714 54.2796 64.1365C54.5254 64.3371 54.7267 64.5866 54.8708 64.8693C55.015 65.1518 55.0987 65.4614 55.1168 65.7781H53.1438C53.1205 65.5563 53.0249 65.3484 52.8717 65.1864C52.793 65.1119 52.7 65.054 52.5984 65.0164C52.4967 64.9789 52.3884 64.9622 52.2802 64.9676C52.0931 64.9581 51.9094 65.0194 51.7655 65.1392C51.6968 65.2022 51.6435 65.28 51.6092 65.3665C51.5749 65.453 51.5605 65.5463 51.5673 65.639C51.5655 65.7225 51.5804 65.8054 51.6109 65.883C51.6413 65.9608 51.6868 66.0317 51.7448 66.0916C51.8705 66.2186 52.0204 66.3191 52.1854 66.3874C52.3629 66.4644 52.6174 66.5649 52.9515 66.6832C53.3572 66.8157 53.7527 66.9779 54.1347 67.1682C54.4483 67.3359 54.7178 67.575 54.9215 67.8664C55.1544 68.2157 55.2704 68.6299 55.2528 69.0494C55.2599 69.4458 55.1532 69.836 54.9452 70.1735Z" fill="white"/>
<path d="M60.6197 65.5475C61.0796 65.7878 61.4606 66.1554 61.7171 66.6065C61.9794 67.1055 62.1163 67.6608 62.1163 68.2244C62.1163 68.7881 61.9794 69.3433 61.7171 69.8424C61.4609 70.2944 61.0799 70.6629 60.6197 70.9043C60.1266 71.1457 59.5849 71.2711 59.0357 71.2711C58.4867 71.2711 57.945 71.1457 57.4518 70.9043C56.9894 70.6639 56.6063 70.2953 56.3485 69.8424C56.0864 69.3433 55.9493 68.7881 55.9493 68.2244C55.9493 67.6608 56.0864 67.1055 56.3485 66.6065C56.6065 66.1545 56.9897 65.7868 57.4518 65.5475C57.945 65.3062 58.4867 65.1807 59.0357 65.1807C59.5849 65.1807 60.1266 65.3062 60.6197 65.5475ZM58.1706 67.133C57.9157 67.4371 57.7884 67.8284 57.8156 68.2244C57.7878 68.6187 57.9152 69.0083 58.1706 69.3099C58.2842 69.4308 58.4219 69.5264 58.5746 69.5907C58.7275 69.6547 58.8922 69.6862 59.058 69.6827C59.224 69.6877 59.389 69.657 59.5422 69.5926C59.6953 69.5284 59.8328 69.432 59.9453 69.3099C60.1975 69.0067 60.3226 68.6178 60.2944 68.2244C60.322 67.8293 60.197 67.4387 59.9453 67.133C59.8336 67.0096 59.6963 66.9118 59.5431 66.8464C59.3899 66.7811 59.2244 66.7497 59.058 66.7544C58.8918 66.7516 58.7269 66.7838 58.574 66.8491C58.4211 66.9144 58.2836 67.0111 58.1706 67.133Z" fill="white"/>
<path d="M65.9827 64.4435C65.7493 64.4231 65.5169 64.4919 65.3319 64.6357C65.1806 64.8054 65.1001 65.0266 65.1072 65.2539H66.1839V66.786H65.1072V71.1962H63.2882V66.786H62.5752V65.2539H63.2882V65.1977C63.2681 64.8833 63.3154 64.5683 63.4267 64.2736C63.5378 63.9787 63.7104 63.711 63.9328 63.488C64.3608 63.0937 64.9829 62.8965 65.7993 62.8965C65.9708 62.8965 66.0952 62.8965 66.1839 62.8965V64.4612L65.9827 64.4435Z" fill="white"/>
<path d="M70.3511 69.6357V71.2033H69.5288C68.942 71.2385 68.3632 71.0541 67.905 70.6857C67.5204 70.3426 67.3135 69.7717 67.3135 68.9761V66.7931H66.5147V65.261H67.3135V63.8027H69.1354V65.261H70.3186V66.7931H69.1354V69.0086C69.1172 69.1846 69.1681 69.3606 69.2774 69.4996C69.4165 69.6041 69.5895 69.6525 69.7625 69.6357H70.3511Z" fill="white"/>
<path d="M80.0547 65.2539L78.5314 71.1963H76.5053L75.4317 67.212L74.3461 71.1963H72.311L70.7877 65.2539H72.6098L73.3966 69.5339L74.5058 65.2539H76.4432L77.582 69.5458L78.3688 65.2539H80.0547Z" fill="white"/>
<path d="M84.1417 65.4947C84.4626 65.6951 84.7153 65.9879 84.8664 66.3347V65.261H86.6766V71.2034H84.8664V70.1266C84.7161 70.474 84.4633 70.767 84.1417 70.9667C83.7844 71.1782 83.3733 71.281 82.9585 71.2625C82.4937 71.2687 82.0369 71.1414 81.6423 70.8956C81.2434 70.6351 80.9266 70.2669 80.7283 69.8339C80.5042 69.3207 80.3885 68.7669 80.3885 68.2071C80.3885 67.6471 80.5042 67.0933 80.7283 66.5802C80.9278 66.1501 81.2445 65.7851 81.6423 65.5271C82.0363 65.2803 82.4936 65.1529 82.9585 65.1605C83.3772 65.1535 83.7885 65.2696 84.1417 65.4947ZM82.6125 67.1658C82.3821 67.473 82.2575 67.8466 82.2575 68.2306C82.2575 68.6147 82.3821 68.9884 82.6125 69.2955C82.7364 69.4194 82.8835 69.5178 83.0453 69.5849C83.2072 69.652 83.3809 69.6865 83.5561 69.6865C83.7313 69.6865 83.9048 69.652 84.0667 69.5849C84.2287 69.5178 84.3757 69.4194 84.4995 69.2955C84.7372 68.9947 84.8664 68.6228 84.8664 68.2396C84.8664 67.8563 84.7372 67.4844 84.4995 67.1836C84.2483 66.9353 83.9092 66.7963 83.5561 66.7963C83.2028 66.7963 82.8639 66.9353 82.6125 67.1836V67.1658Z" fill="white"/>
<path d="M90.6347 65.5081C90.9918 65.294 91.4014 65.1833 91.8179 65.1887V67.138H91.2943C90.8974 67.1105 90.5023 67.2145 90.1703 67.4337C90.0269 67.5646 89.9163 67.7272 89.8469 67.9085C89.7776 68.0898 89.7515 68.2847 89.771 68.4779V71.1844H87.9519V65.2538H89.771V66.3807C89.9846 66.0246 90.2808 65.7254 90.6347 65.5081Z" fill="white"/>
<path d="M98.163 68.5563H94.0398C94.0267 68.9098 94.1495 69.2549 94.3829 69.5206C94.488 69.6194 94.6117 69.6962 94.7467 69.7471C94.8818 69.7978 95.0254 69.8213 95.1697 69.8163C95.41 69.8254 95.6464 69.7536 95.8411 69.6123C96.0141 69.4838 96.1382 69.3001 96.193 69.0918H98.1186C98.0438 69.5025 97.8692 69.8884 97.6099 70.2158C97.3413 70.5492 96.9977 70.8147 96.6072 70.9907C96.184 71.1881 95.7223 71.2892 95.2554 71.2865C94.7162 71.296 94.1832 71.1699 93.7054 70.9197C93.2571 70.6758 92.8892 70.3069 92.6466 69.8578C92.3804 69.3608 92.2479 68.8033 92.262 68.2399C92.2483 67.6752 92.3788 67.1164 92.6407 66.6159C92.8828 66.1688 93.251 65.8026 93.6995 65.563C94.1828 65.3237 94.7148 65.1992 95.2539 65.1992C95.7932 65.1992 96.3251 65.3237 96.8084 65.563C97.2464 65.7933 97.6073 66.1471 97.8466 66.5806C98.0989 67.0415 98.2264 67.5606 98.2163 68.0861C98.2134 68.2441 98.1956 68.4016 98.163 68.5563ZM96.06 66.9118C95.8391 66.7146 95.5514 66.6088 95.2554 66.6159C94.9506 66.6038 94.6528 66.7098 94.4242 66.9118C94.1881 67.147 94.0542 67.4659 94.0516 67.7991H96.3735C96.3901 67.6373 96.3706 67.4738 96.3164 67.3203C96.2622 67.1668 96.1746 67.0272 96.06 66.9118Z" fill="white"/>
<path d="M49.8272 81.803C49.5127 82.3719 49.0386 82.8367 48.4637 83.1399C47.8166 83.4722 47.0966 83.637 46.3695 83.619H43.527V76.0884H46.3695C47.0975 76.0697 47.819 76.2312 48.4696 76.5587C49.0429 76.8542 49.5154 77.3133 49.8272 77.8778C50.1382 78.4853 50.3003 79.158 50.3003 79.8405C50.3003 80.5228 50.1382 81.1956 49.8272 81.803ZM47.8662 81.4244C48.2475 80.9836 48.4573 80.4203 48.4573 79.8375C48.4573 79.2546 48.2475 78.6913 47.8662 78.2506C47.4098 77.8517 46.8147 77.6487 46.2098 77.6856H45.3491V81.9893H46.2098C46.8147 82.0262 47.4098 81.8232 47.8662 81.4244Z" fill="white"/>
<path d="M56.9162 80.9784H52.796C52.7808 81.3316 52.9027 81.677 53.1361 81.9427C53.2413 82.0412 53.3651 82.1181 53.5 82.1689C53.635 82.2196 53.7788 82.2433 53.9229 82.2385C54.1632 82.248 54.3999 82.1761 54.5943 82.0344C54.7683 81.9067 54.8926 81.7227 54.9464 81.5138H56.8749C56.799 81.9246 56.6233 82.3107 56.3631 82.6378C56.0945 82.9713 55.7509 83.2369 55.3604 83.4127C54.9374 83.6105 54.4757 83.7117 54.0087 83.7085C53.4695 83.7177 52.9366 83.5917 52.4588 83.3418C52.0114 83.0966 51.6438 82.728 51.3998 82.2799C51.1488 81.7743 51.0182 81.2174 51.0182 80.6531C51.0182 80.0886 51.1488 79.5318 51.3998 79.0262C51.643 78.5788 52.0123 78.2126 52.4617 77.9732C52.945 77.7339 53.4768 77.6094 54.0161 77.6094C54.5552 77.6094 55.0873 77.7339 55.5704 77.9732C56.0073 78.2053 56.3678 78.5586 56.6086 78.9907C56.8602 79.452 56.9866 79.9711 56.9754 80.4962C56.9713 80.6585 56.9515 80.8199 56.9162 80.9784ZM54.8132 79.3338C54.5926 79.1362 54.3047 79.0302 54.0087 79.0381C53.7041 79.0273 53.4068 79.1331 53.1776 79.3338C52.9413 79.569 52.8074 79.8879 52.8048 80.2211H55.1267C55.144 80.0593 55.1248 79.8955 55.0706 79.742C55.0162 79.5885 54.9283 79.4489 54.8132 79.3338Z" fill="white"/>
<path d="M60.6664 81.8393L61.956 77.6777H63.8934L61.7845 83.62H59.5275L57.4186 77.6777H59.3531L60.6664 81.8393Z" fill="white"/>
<path d="M70.2441 80.9791H66.1207C66.1077 81.3326 66.2305 81.6777 66.4639 81.9434C66.5691 82.0422 66.6927 82.1191 66.8278 82.1699C66.9628 82.2206 67.1066 82.2442 67.2506 82.2392C67.491 82.2483 67.7275 82.1765 67.9222 82.0351C68.0951 81.9067 68.2193 81.723 68.2741 81.5145H70.1996C70.125 81.9259 69.9492 82.3122 69.6879 82.6385C69.421 82.9727 69.0783 83.2383 68.6881 83.4136C68.265 83.6109 67.8033 83.7121 67.3364 83.7093C66.7973 83.7189 66.2642 83.5927 65.7866 83.3425C65.3381 83.0987 64.9702 82.7297 64.7276 82.2806C64.4614 81.7837 64.329 81.2262 64.3431 80.6627C64.3295 80.0981 64.4597 79.5392 64.7216 79.0388C64.9638 78.5916 65.332 78.2254 65.7806 77.9859C66.2638 77.7466 66.7958 77.6221 67.3349 77.6221C67.8742 77.6221 68.406 77.7466 68.8893 77.9859C69.3273 78.2162 69.6884 78.5699 69.9275 79.0034C70.18 79.4644 70.3074 79.9835 70.2972 80.5089C70.2943 80.667 70.2766 80.8244 70.2441 80.9791ZM68.141 79.3347C67.9202 79.1375 67.6325 79.0316 67.3364 79.0388C67.0315 79.0266 66.7338 79.1326 66.5053 79.3347C66.2691 79.5699 66.1352 79.8887 66.1326 80.222H68.4545C68.4712 80.0601 68.4516 79.8965 68.3974 79.7431C68.3432 79.5896 68.2556 79.4501 68.141 79.3347Z" fill="white"/>
<path d="M73.0575 75.7402V83.62H71.2385V75.7402H73.0575Z" fill="white"/>
<path d="M78.6733 77.9699C79.134 78.2092 79.5151 78.5771 79.7708 79.0287C80.033 79.5278 80.1699 80.083 80.1699 80.6468C80.1699 81.2104 80.033 81.7656 79.7708 82.2647C79.5152 82.7173 79.1341 83.0861 78.6733 83.3265C78.1848 83.5758 77.6424 83.7017 77.0939 83.6934C76.5426 83.701 75.9977 83.5752 75.5056 83.3265C75.044 83.0861 74.6619 82.7174 74.4051 82.2647C74.1431 81.7656 74.006 81.2104 74.006 80.6468C74.006 80.083 74.1431 79.5278 74.4051 79.0287C74.6619 78.5769 75.0442 78.209 75.5056 77.9699C75.9972 77.7198 76.5424 77.594 77.0939 77.6031C77.6426 77.5932 78.1852 77.7192 78.6733 77.9699ZM76.2095 79.5553C75.9521 79.8583 75.8235 80.2501 75.8516 80.6468C75.8232 81.0415 75.9518 81.4317 76.2095 81.7322C76.3223 81.854 76.4598 81.9502 76.6129 82.0144C76.7659 82.0788 76.931 82.1096 77.0969 82.1049C77.2629 82.1106 77.4282 82.0802 77.5814 82.0158C77.7346 81.9514 77.872 81.8547 77.9842 81.7322C78.2374 81.4295 78.3636 81.0405 78.3363 80.6468C78.363 80.2513 78.237 79.8606 77.9842 79.5553C77.8726 79.4315 77.7355 79.3336 77.5823 79.2682C77.4291 79.2027 77.2635 79.1716 77.0969 79.1766C76.9305 79.1724 76.765 79.204 76.612 79.2692C76.4588 79.3347 76.3215 79.4322 76.2095 79.5553Z" fill="white"/>
<path d="M86.1381 77.9704C86.5368 78.2282 86.8546 78.5931 87.055 79.0233C87.2773 79.537 87.3919 80.0907 87.3919 80.6502C87.3919 81.2098 87.2773 81.7635 87.055 82.277C86.8557 82.7103 86.5379 83.0785 86.1381 83.3389C85.7435 83.5846 85.2867 83.712 84.8219 83.7057C84.4071 83.7244 83.996 83.6216 83.6387 83.41C83.317 83.213 83.0662 82.9188 82.923 82.5699V86.4772H81.1187V77.6775H82.9377V78.7513C83.0826 78.4033 83.3329 78.1095 83.6536 77.9112C84.0107 77.6995 84.4219 77.5968 84.8367 77.6154C85.2955 77.6072 85.747 77.7304 86.1381 77.9704ZM83.3074 79.5883C83.07 79.8891 82.9408 80.261 82.9408 80.6443C82.9408 81.0275 83.07 81.3996 83.3074 81.7002C83.4312 81.8241 83.578 81.9223 83.7395 81.9895C83.9012 82.0564 84.0745 82.091 84.2495 82.091C84.4246 82.091 84.5978 82.0564 84.7595 81.9895C84.9212 81.9223 85.068 81.8241 85.1916 81.7002C85.4221 81.3931 85.5466 81.0194 85.5466 80.6355C85.5466 80.2515 85.4221 79.8778 85.1916 79.5705C84.9408 79.3226 84.6022 79.1835 84.2495 79.1835C83.8968 79.1835 83.5583 79.3226 83.3074 79.5705V79.5883Z" fill="white"/>
<path d="M93.9319 80.9792H89.8206C89.8067 81.3323 89.9284 81.6773 90.1607 81.9434C90.3746 82.1425 90.6585 82.2488 90.9505 82.2394C91.1908 82.2483 91.4272 82.1765 91.6219 82.0351C91.7951 81.9073 91.9184 81.7233 91.9709 81.5147H93.8994C93.8215 81.9317 93.6393 82.3226 93.37 82.6505C93.1023 82.9839 92.7598 83.2493 92.3702 83.4254C91.9471 83.6228 91.4854 83.7239 91.0185 83.7212C90.4792 83.7308 89.9463 83.6046 89.4686 83.3544C89.0195 83.1115 88.6513 82.7424 88.4096 82.2925C88.1435 81.7956 88.0109 81.238 88.025 80.6746C88.0115 80.11 88.1418 79.5511 88.4037 79.0507C88.6449 78.6029 89.0134 78.2364 89.4627 77.9977C89.9458 77.7584 90.4778 77.6338 91.017 77.6338C91.5563 77.6338 92.0881 77.7584 92.5714 77.9977C93.0082 78.2297 93.3688 78.5832 93.6096 79.0153C93.861 79.4765 93.9875 79.9955 93.9764 80.5208C93.9756 80.6746 93.9608 80.8281 93.9319 80.9792ZM91.8289 79.3347C91.6096 79.1369 91.3226 79.0309 91.0273 79.0388C90.7226 79.0267 90.4247 79.1326 90.1962 79.3347C90.0782 79.4504 89.9844 79.5884 89.9205 79.7407C89.8564 79.8932 89.8234 80.0568 89.8234 80.222H92.1454C92.1624 80.0599 92.1426 79.896 92.0878 79.7424C92.033 79.5889 91.9446 79.4495 91.8289 79.3347Z" fill="white"/>
<path d="M97.6302 77.9329C97.9874 77.7191 98.3969 77.6086 98.8132 77.6134V79.5626H98.2897C97.8929 79.5353 97.4978 79.6391 97.1657 79.8584C97.0224 79.9892 96.9117 80.1519 96.8424 80.3331C96.773 80.5144 96.747 80.7094 96.7664 80.9025V83.609H94.9474V77.6784H96.7664V78.8054C96.9801 78.4494 97.2763 78.15 97.6302 77.9329Z" fill="white"/>
<path d="M103.535 78.166C103.983 78.543 104.283 79.0672 104.381 79.6449H102.677C102.636 79.422 102.518 79.2209 102.343 79.0769C102.155 78.9339 101.923 78.8607 101.686 78.87C101.52 78.8585 101.354 78.9055 101.219 79.003C101.163 79.0513 101.12 79.1123 101.093 79.1806C101.065 79.2491 101.055 79.323 101.062 79.3964C101.062 79.4879 101.091 79.5772 101.144 79.6519C101.197 79.7265 101.272 79.7829 101.358 79.8135C101.654 79.9367 101.962 80.0289 102.278 80.0886C102.657 80.1731 103.03 80.2817 103.396 80.4139C103.69 80.5254 103.951 80.7108 104.153 80.9523C104.378 81.2353 104.492 81.5909 104.472 81.952C104.479 82.2757 104.382 82.593 104.194 82.8572C103.993 83.1353 103.716 83.3498 103.396 83.4754C103.004 83.6339 102.585 83.7104 102.162 83.7001C101.479 83.7347 100.803 83.5481 100.234 83.1676C99.9911 82.9865 99.7874 82.7583 99.6349 82.4966C99.4823 82.2348 99.3843 81.945 99.3466 81.6443H101.104C101.111 81.7608 101.144 81.8742 101.2 81.9764C101.257 82.0786 101.335 82.1672 101.429 82.236C101.645 82.3789 101.901 82.4493 102.16 82.437C102.327 82.449 102.494 82.3985 102.627 82.2951C102.679 82.2474 102.721 82.1888 102.748 82.1235C102.776 82.0581 102.789 81.9875 102.787 81.9166C102.79 81.8212 102.763 81.7274 102.71 81.6483C102.657 81.569 102.58 81.5088 102.491 81.4758C102.183 81.3486 101.864 81.2496 101.538 81.1801C101.169 81.1092 100.807 81.0103 100.453 80.8843C100.168 80.7779 99.9142 80.6008 99.7163 80.3696C99.5016 80.0913 99.3964 79.7441 99.4206 79.3935C99.4137 79.1457 99.4649 78.8996 99.5703 78.6752C99.6755 78.4508 99.8319 78.254 100.027 78.1008C100.523 77.7425 101.129 77.5679 101.739 77.6069C102.385 77.5754 103.021 77.7733 103.535 78.166Z" fill="white"/>
<path d="M67.0918 96.0674C67.0918 95.615 66.8966 95.3871 66.5002 95.3871C66.406 95.3807 66.3117 95.3988 66.2267 95.4398C66.1417 95.4807 66.0688 95.5431 66.0151 95.6209C65.8974 95.8416 65.8393 96.0894 65.8465 96.3396H64.4622C64.4567 96.042 64.5111 95.7464 64.6224 95.4706C64.7338 95.1945 64.8996 94.9439 65.11 94.7334C65.5248 94.3684 66.0634 94.1759 66.6156 94.1951C66.8752 94.1753 67.1362 94.2078 67.383 94.2907C67.6301 94.3735 67.8578 94.5049 68.0531 94.6773C68.2151 94.8411 68.3417 95.0363 68.425 95.251C68.5085 95.4657 68.547 95.6953 68.5382 95.9254C68.5202 96.5414 68.2892 97.132 67.8845 97.5966C67.4445 98.1412 66.9154 98.6073 66.3198 98.9751H68.6299V100.117H64.4593V99.0578C66.2143 97.8037 67.0918 96.807 67.0918 96.0674Z" fill="white"/>
<path d="M69.2066 97.1943C69.1659 96.4332 69.3588 95.6777 69.7597 95.0292C70.1303 94.4928 70.7219 94.2256 71.5344 94.2275C72.3468 94.2295 72.9383 94.4967 73.3091 95.0292C73.6758 95.6941 73.868 96.4409 73.868 97.2002C73.868 97.9595 73.6758 98.7065 73.3091 99.3713C72.9364 99.9078 72.3448 100.176 71.5344 100.176C70.7239 100.176 70.1322 99.9078 69.7597 99.3713C69.3584 98.7186 69.1654 97.9593 69.2066 97.1943ZM72.4601 97.1943C72.4794 96.7836 72.416 96.3733 72.2738 95.9875C72.2109 95.8503 72.1066 95.7364 71.9756 95.6616C71.8446 95.5867 71.6933 95.5548 71.5432 95.5705C71.3903 95.5539 71.236 95.5858 71.1023 95.6617C70.9685 95.7376 70.8621 95.8536 70.7979 95.9935C70.6523 96.3764 70.5868 96.7851 70.6055 97.1943C70.587 97.6074 70.6524 98.0199 70.7979 98.4071C70.8612 98.5477 70.9675 98.6646 71.1014 98.7411C71.2353 98.8176 71.3898 98.8498 71.5432 98.833C71.6944 98.8483 71.8465 98.8153 71.9777 98.7388C72.1091 98.6623 72.2127 98.5462 72.2738 98.4071C72.4124 98.0186 72.4727 97.6063 72.4513 97.1943H72.4601Z" fill="white"/>
<path d="M77.0959 96.0674C77.0959 95.615 76.9007 95.3871 76.5042 95.3871C76.4101 95.3807 76.3158 95.3988 76.2308 95.4398C76.1458 95.4807 76.0729 95.5431 76.0191 95.6209C75.9015 95.8416 75.8435 96.0894 75.8506 96.3396H74.4662C74.46 96.0419 74.5142 95.7461 74.6255 95.4701C74.7369 95.1941 74.903 94.9434 75.1141 94.7334C75.5289 94.3684 76.0674 94.1759 76.6196 94.1951C76.8792 94.1753 77.1402 94.2078 77.3873 94.2907C77.6341 94.3735 77.8619 94.5049 78.0571 94.6773C78.2191 94.8411 78.3458 95.0363 78.4293 95.251C78.5126 95.4657 78.5512 95.6953 78.5422 95.9254C78.5243 96.5414 78.2934 97.132 77.8886 97.5966C77.4483 98.142 76.9181 98.6083 76.321 98.9751H78.6339V100.117H74.4693V99.0578C76.2203 97.8037 77.0959 96.807 77.0959 96.0674Z" fill="white"/>
<path d="M81.7986 96.0674C81.7986 95.615 81.6004 95.3871 81.2069 95.3871C81.1128 95.3807 81.0185 95.3988 80.9334 95.4398C80.8484 95.4807 80.7755 95.5431 80.7218 95.6209C80.6028 95.8412 80.5445 96.0893 80.5533 96.3396H79.1719C79.1659 96.0422 79.2198 95.7467 79.3306 95.4707C79.4413 95.1947 79.6068 94.944 79.8168 94.7334C80.2329 94.3689 80.7724 94.1764 81.3253 94.1951C81.5849 94.1753 81.8459 94.2078 82.0928 94.2907C82.3397 94.3735 82.5676 94.5049 82.7628 94.6773C82.9248 94.8411 83.0515 95.0363 83.1348 95.251C83.2183 95.4657 83.2567 95.6953 83.2479 95.9254C83.2291 96.5417 82.9971 97.1325 82.5913 97.5966C82.1528 98.1426 81.6235 98.6089 81.0265 98.9751H83.3366V100.117H79.1719V99.0578C80.923 97.8037 81.7986 96.807 81.7986 96.0674Z" fill="white"/>
<path d="M41.722 33.037V34.0812H41.0862C40.6419 34.1109 40.2027 33.9726 39.8556 33.6937C39.5598 33.4335 39.4297 33.0015 39.4297 32.4012V30.3305H38.7672V29.3072H39.4297V28.124H40.6454V29.3072H41.7132V30.3305H40.6454V32.4012C40.6277 32.5738 40.6776 32.7466 40.7845 32.8832C40.8487 32.9361 40.923 32.9755 41.0027 32.9994C41.0824 33.0234 41.166 33.0311 41.2488 33.0223L41.722 33.037Z" fill="white"/>
<path d="M47.0357 31.9266H43.5366C43.5242 32.2727 43.6473 32.6103 43.8796 32.8671C44.0946 33.0663 44.3794 33.1726 44.6723 33.1629C44.9234 33.1732 45.1709 33.1005 45.3763 32.9558C45.5602 32.8198 45.6876 32.6207 45.7342 32.3968H47.0179C46.9558 32.7282 46.8136 33.0393 46.6039 33.3032C46.3941 33.5672 46.1229 33.7758 45.8141 33.9113C45.4752 34.0649 45.1066 34.1417 44.7346 34.136C44.302 34.1442 43.8746 34.0424 43.4922 33.8402C43.1349 33.6462 42.842 33.3522 42.6492 32.9942C42.4497 32.5884 42.346 32.1422 42.346 31.6899C42.346 31.2376 42.4497 30.7913 42.6492 30.3855C42.8405 30.0257 43.1338 29.7304 43.4922 29.5365C43.8751 29.3356 44.3022 29.2339 44.7346 29.2407C45.1652 29.2322 45.5907 29.3341 45.9709 29.5365C46.3148 29.7229 46.5989 30.0029 46.7902 30.3441C46.9891 30.6999 47.0911 31.1018 47.0861 31.5095C47.0811 31.6496 47.0642 31.7892 47.0357 31.9266ZM45.5567 30.5067C45.3338 30.3072 45.0425 30.2012 44.7434 30.2109C44.4396 30.1995 44.143 30.3053 43.9152 30.5067C43.7973 30.6224 43.7038 30.7605 43.6403 30.913C43.5767 31.0654 43.5445 31.2289 43.5455 31.3941H45.8645C45.8824 31.2318 45.8633 31.0677 45.8084 30.914C45.7535 30.7602 45.6645 30.621 45.5479 30.5067H45.5567Z" fill="white"/>
<path d="M51.6272 29.745C52.0437 30.0876 52.3187 30.5723 52.3993 31.1055H51.1156C51.0712 30.8724 50.9454 30.6628 50.7606 30.514C50.5599 30.363 50.3135 30.2858 50.0625 30.295C49.9104 30.2921 49.7594 30.3227 49.6205 30.3845C49.4814 30.4464 49.3577 30.538 49.258 30.6529C49.0478 30.9617 48.9355 31.3266 48.9355 31.7C48.9355 32.0735 49.0478 32.4384 49.258 32.7471C49.3582 32.8615 49.4821 32.9526 49.621 33.0144C49.7599 33.0762 49.9105 33.1072 50.0625 33.1051C50.3139 33.1146 50.5609 33.0363 50.7606 32.8832C50.9454 32.7343 51.0712 32.5246 51.1156 32.2916H52.3993C52.3187 32.8248 52.0437 33.3094 51.6272 33.6523C51.1892 33.9943 50.6446 34.1714 50.0892 34.1521C49.6576 34.1606 49.2311 34.0589 48.8498 33.8563C48.4908 33.6638 48.1965 33.3694 48.0039 33.0103C47.8045 32.6045 47.7008 32.1583 47.7008 31.706C47.7008 31.2537 47.8045 30.8075 48.0039 30.4016C48.1952 30.0407 48.4897 29.7451 48.8498 29.5526C49.2315 29.3514 49.6577 29.2495 50.0892 29.2568C50.6432 29.2344 51.1875 29.407 51.6272 29.745Z" fill="white"/>
<path d="M57.4218 29.7815C57.7732 30.2084 57.9497 30.7527 57.9157 31.3048V34.0822H56.709V31.4437C56.7301 31.1206 56.6238 30.8022 56.4132 30.5564C56.3098 30.4497 56.1847 30.3662 56.0466 30.3115C55.9083 30.257 55.7601 30.2326 55.6116 30.2399C55.4575 30.233 55.3035 30.2597 55.1607 30.3179C55.0176 30.376 54.889 30.4646 54.7834 30.5771C54.5656 30.8491 54.46 31.1939 54.4876 31.5413V34.0762H53.2719V27.7051H54.4876V30.2635C54.6068 29.9502 54.8262 29.6851 55.1118 29.5093C55.4144 29.3251 55.7631 29.2308 56.1173 29.2372C56.3613 29.2278 56.6044 29.2715 56.8296 29.3655C57.0549 29.4595 57.2569 29.6014 57.4218 29.7815Z" fill="white"/>
<path d="M60.898 29.5007C61.195 29.3246 61.5348 29.2335 61.8801 29.2374V30.5389H61.528C60.6387 30.5389 60.194 30.9529 60.194 31.7812V34.0824H58.9783V29.3083H60.194V30.2195C60.3627 29.9231 60.6053 29.6755 60.898 29.5007Z" fill="white"/>
<path d="M67.0034 31.9256H63.4952C63.4825 32.2725 63.6068 32.6105 63.8414 32.8663C64.0561 33.0657 64.3411 33.1721 64.6341 33.1621C64.8842 33.172 65.1306 33.0992 65.3351 32.955C65.52 32.8199 65.6477 32.6205 65.693 32.396H66.9767C66.9143 32.7198 66.7753 33.0241 66.5714 33.2833C66.3604 33.5509 66.0874 33.7632 65.7758 33.9015C65.4074 34.0615 65.0081 34.1379 64.6066 34.1252C64.205 34.1122 63.8113 34.0107 63.4539 33.8276C63.0949 33.6351 62.8005 33.3407 62.6079 32.9816C62.4084 32.5757 62.3047 32.1295 62.3047 31.6772C62.3047 31.2249 62.4084 30.7787 62.6079 30.3727C62.7993 30.012 63.0938 29.7164 63.4539 29.5238C63.8355 29.3227 64.2618 29.2208 64.6932 29.2281C65.1239 29.2191 65.5496 29.321 65.9296 29.5238C66.2741 29.7094 66.5585 29.9895 66.7489 30.3314C66.9477 30.6873 67.0497 31.0892 67.0447 31.4969C67.0448 31.6408 67.031 31.7844 67.0034 31.9256ZM65.5244 30.5059C65.3015 30.3061 65.0102 30.2001 64.7109 30.2101C64.4082 30.1993 64.1127 30.3053 63.8857 30.5059C63.7675 30.6214 63.6735 30.7594 63.6096 30.9119C63.5455 31.0643 63.5127 31.228 63.513 31.3933H65.832C65.8507 31.231 65.8317 31.0666 65.7769 30.9128C65.7221 30.759 65.6327 30.6197 65.5155 30.5059H65.5244Z" fill="white"/>
<path d="M69.9813 32.8668L71.188 29.3174H72.4807L70.706 34.0913H69.227L67.4523 29.3174H68.7537L69.9813 32.8668Z" fill="white"/>
<path d="M73.2213 27.4597C73.3712 27.3305 73.5649 27.2638 73.7626 27.2734C73.961 27.2643 74.1555 27.3309 74.3067 27.4597C74.372 27.5201 74.4241 27.5933 74.4596 27.6746C74.4953 27.7562 74.5137 27.8441 74.5137 27.9329C74.5137 28.0219 74.4953 28.1098 74.4596 28.1912C74.4241 28.2727 74.372 28.3459 74.3067 28.4062C74.1555 28.5351 73.961 28.6017 73.7626 28.5926C73.5649 28.6022 73.3712 28.5354 73.2213 28.4062C73.156 28.3459 73.104 28.2727 73.0684 28.1912C73.0327 28.1098 73.0144 28.0219 73.0144 27.9329C73.0144 27.8441 73.0327 27.7562 73.0684 27.6746C73.104 27.5933 73.156 27.5201 73.2213 27.4597ZM74.366 29.3083V34.0824H73.1503V29.3083H74.366Z" fill="white"/>
<path d="M79.9188 31.9266H76.4226C76.415 32.0981 76.4415 32.2697 76.5004 32.4311C76.5591 32.5925 76.6493 32.7407 76.7658 32.8672C76.9804 33.0667 77.2654 33.1729 77.5585 33.1629C77.8085 33.1728 78.0548 33.1001 78.2594 32.9558C78.4453 32.8216 78.5731 32.6218 78.6173 32.3968H79.9041C79.8418 32.7282 79.6997 33.0393 79.4899 33.3034C79.28 33.5672 79.0089 33.7758 78.7002 33.9113C78.3317 34.0713 77.9324 34.1477 77.5308 34.1348C77.1294 34.122 76.7357 34.0204 76.3783 33.8373C76.0203 33.6442 75.7271 33.35 75.5352 32.9913C75.3358 32.5855 75.232 32.1391 75.232 31.687C75.232 31.2347 75.3358 30.7885 75.5352 30.3825C75.7254 30.0219 76.0189 29.7263 76.3783 29.5336C76.7598 29.3323 77.1862 29.2306 77.6175 29.2378C78.0492 29.2289 78.4758 29.3308 78.8569 29.5336C79.1997 29.7204 79.4827 30.0003 79.6733 30.341C79.872 30.6971 79.9739 31.0988 79.969 31.5065C79.9657 31.6478 79.9488 31.7884 79.9188 31.9266ZM78.4398 30.5067C78.2151 30.3082 77.9233 30.2026 77.6234 30.2109C77.3207 30.2003 77.0252 30.3061 76.7983 30.5067C76.6798 30.6219 76.586 30.7601 76.5224 30.9125C76.4589 31.0651 76.4269 31.2289 76.4286 31.3941H78.7445C78.7638 31.2323 78.7459 31.0683 78.6922 30.9143C78.6383 30.7605 78.5501 30.6212 78.4339 30.5067H78.4398Z" fill="white"/>
<path d="M87.661 29.3076L86.3773 34.0817H85.0315L84.0051 30.6091L82.9551 34.0817H81.6093L80.3344 29.3076H81.5382L82.3132 32.9961L83.375 29.3076H84.6588L85.7266 32.9961L86.5105 29.3076H87.661Z" fill="white"/>
<path d="M92.7108 31.9264H89.2116C89.1992 32.2725 89.3223 32.6101 89.5548 32.8669C89.7696 33.0661 90.0546 33.1724 90.3475 33.1627C90.5984 33.173 90.8459 33.1003 91.0513 32.9558C91.2352 32.8196 91.3626 32.6207 91.4093 32.3967H92.693C92.6305 32.7206 92.4916 33.0247 92.2878 33.284C92.0756 33.5517 91.8015 33.764 91.4892 33.9023C91.1523 34.0606 90.7847 34.1425 90.4124 34.1418C89.98 34.1499 89.5526 34.0482 89.1702 33.8461C88.8129 33.6521 88.52 33.358 88.3272 33.0001C88.1277 32.5941 88.024 32.1479 88.024 31.6956C88.024 31.2434 88.1277 30.7972 88.3272 30.3912C88.5185 30.0316 88.8117 29.7362 89.1702 29.5424C89.5531 29.3413 89.9802 29.2398 90.4124 29.2466C90.843 29.2379 91.2687 29.3398 91.6489 29.5424C91.9928 29.7287 92.2769 30.0086 92.4682 30.3498C92.6671 30.7057 92.769 31.1076 92.7639 31.5152C92.7577 31.6535 92.74 31.7911 92.7108 31.9264ZM91.2318 30.5066C91.0088 30.307 90.7175 30.201 90.4184 30.2109C90.1145 30.1993 89.818 30.3053 89.5901 30.5066C89.4728 30.6226 89.3797 30.7608 89.3162 30.9132C89.2528 31.0655 89.2201 31.2289 89.2204 31.3939H91.5394C91.5574 31.2318 91.5382 31.0675 91.4834 30.9138C91.4286 30.76 91.3395 30.6208 91.2229 30.5066H91.2318Z" fill="white"/>
<path d="M95.5328 29.5007C95.8299 29.3246 96.1695 29.2335 96.5148 29.2374V30.5389H96.1628C95.2736 30.5389 94.8289 30.9529 94.8289 31.7812V34.0824H93.6132V29.3083H94.8289V30.2195C94.9976 29.9231 95.24 29.6755 95.5328 29.5007Z" fill="white"/>
<path d="M98.1958 32.8115V34.0805H96.8856V32.8115H98.1958Z" fill="white"/>
<path d="M102.687 29.745C103.104 30.0876 103.379 30.5723 103.459 31.1055H102.176C102.131 30.8724 102.005 30.6628 101.821 30.514C101.62 30.363 101.373 30.2858 101.123 30.295C100.97 30.2921 100.819 30.3227 100.68 30.3845C100.541 30.4464 100.418 30.538 100.318 30.6529C100.108 30.9617 99.9954 31.3266 99.9954 31.7C99.9954 32.0735 100.108 32.4384 100.318 32.7471C100.418 32.8615 100.542 32.9526 100.681 33.0144C100.82 33.0762 100.971 33.1072 101.123 33.1051C101.374 33.1146 101.621 33.0363 101.821 32.8832C102.005 32.7343 102.131 32.5246 102.176 32.2916H103.459C103.379 32.8248 103.104 33.3094 102.687 33.6523C102.249 33.9943 101.705 34.1714 101.149 34.1521C100.717 34.1603 100.289 34.0584 99.9068 33.8563C99.5496 33.6623 99.2567 33.3683 99.0639 33.0103C98.8644 32.6045 98.7607 32.1583 98.7607 31.706C98.7607 31.2537 98.8644 30.8075 99.0639 30.4016C99.2552 30.0418 99.5485 29.7465 99.9068 29.5526C100.29 29.3517 100.717 29.25 101.149 29.2568C101.703 29.2344 102.248 29.407 102.687 29.745Z" fill="white"/>
<path d="M107.783 29.5391C108.154 29.7303 108.463 30.0251 108.67 30.388C108.878 30.7914 108.987 31.2385 108.987 31.6925C108.987 32.1463 108.878 32.5935 108.67 32.9969C108.461 33.3576 108.153 33.6511 107.783 33.8428C107.394 34.0393 106.964 34.1416 106.528 34.1416C106.093 34.1416 105.663 34.0393 105.274 33.8428C104.904 33.6511 104.596 33.3576 104.387 32.9969C104.179 32.5935 104.07 32.1463 104.07 31.6925C104.07 31.2385 104.179 30.7914 104.387 30.388C104.594 30.0251 104.903 29.7303 105.274 29.5391C105.664 29.3463 106.093 29.2461 106.528 29.2461C106.963 29.2461 107.393 29.3463 107.783 29.5391ZM105.668 30.6542C105.44 30.9513 105.316 31.3151 105.316 31.6895C105.316 32.0639 105.44 32.4277 105.668 32.7248C105.78 32.8384 105.915 32.9284 106.062 32.99C106.21 33.0515 106.368 33.0831 106.528 33.0831C106.688 33.0831 106.847 33.0515 106.995 32.99C107.142 32.9284 107.277 32.8384 107.389 32.7248C107.617 32.4277 107.741 32.0639 107.741 31.6895C107.741 31.3151 107.617 30.9513 107.389 30.6542C107.16 30.427 106.851 30.2995 106.528 30.2995C106.206 30.2995 105.897 30.427 105.668 30.6542Z" fill="white"/>
</svg>
