<script lang="ts">
	import { onMount } from "svelte";
	import { swipe } from 'svelte-gestures';
	import { CarouselHelper } from '$lib/helpers/swipe.helper';
	import ClutchAwardIcon from '$icons/clutch-badge.svelte';
	import GoodfirmsAwardIcon from '$icons/goodfirms-badge.svelte';
	import SoftwareworldAwardIcon from '$icons/softwareworld-badge.svelte';
	import TechReviewAwardIcon from '$icons/techreview-badge.svelte';

	let activeIndex = 0;
	const maxCount = 5;
	const carouselHelper = CarouselHelper.getInstance(maxCount);

	onMount(() => {
			if (!window.matchMedia('(max-width: 1400px)').matches) {
					return;
			}

			carouselHelper.resetInterval();
			carouselHelper.indexStore.subscribe((index) => (activeIndex = Number(index)));
	});
</script>

<div class="awards flex-column">
	<div class="awards-carousel">
		<div class="award"
				 use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
				 on:swipe="{carouselHelper.handleSwipe}"
				 class:active="{activeIndex === 0}"
		>
			<a href="https://clutch.co/profile/exoft" target="_blank" rel="noopener noreferrer nofollow">
				<ClutchAwardIcon />
			</a>
		</div>
		
		<div class="award"
				 use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
				 on:swipe="{carouselHelper.handleSwipe}"
				 class:active="{activeIndex === 1}"
		>
			<a
				href="https://selectedfirms.co/agency/exoft"
				target="_blank"
				rel="noopener noreferrer nofollow">
				<img alt="selected firms" src="/selected-firms-badge.webp" height="150" />
			</a>
		</div>
		
		<div class="award"
				 use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
				 on:swipe="{carouselHelper.handleSwipe}"
				 class:active="{activeIndex === 2}"
		>
			<a
				href="https://techreviewer.co/companies/exoft"
				target="_blank"
				rel="noopener noreferrer nofollow">
				<TechReviewAwardIcon />
			</a>
		</div>
		
		<div class="award"
				 use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
				 on:swipe="{carouselHelper.handleSwipe}"
				 class:active="{activeIndex === 3}"
		>
			<a href="https://www.softwareworld.co/" target="_blank" rel="noopener noreferrer nofollow">
				<SoftwareworldAwardIcon />
			</a>
		</div>
		
		<div class="award"
				 use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
				 on:swipe="{carouselHelper.handleSwipe}"
				 class:active="{activeIndex === 4}"
		>
			<a
				href="https://www.goodfirms.co/company/exoft"
				target="_blank"
				rel="noopener noreferrer nofollow">
				<GoodfirmsAwardIcon />
			</a>
		</div>
	</div>
	
	<ol class="carousel-indicators">
		{#each Array(5) as _, i}
			<li
				on:click="{() => carouselHelper.select(i)}"
				on:keydown="{() => carouselHelper.select(i)}"
				class:active="{i === activeIndex}">
				<div class="carousel-bullet">•</div>
			</li>
		{/each}
	</ol>
</div>

<style lang="scss">
	@import '$styles/vars.scss';

	.awards {
		align-items: center;

		.awards-carousel {
			width: 100%;
			max-width: 100%;
			border-radius: 6px;
			display: flex;
			column-gap: 36px;
			height: 150px;
			justify-content: center;

			.award {
				animation: slidein 500ms ease-out;
				opacity: 0;
				display: none;

				&.active {
					opacity: 1;
					display: block;
				}
			}
		}

		.carousel-indicators {
			margin-top: 24px;
			list-style: none;
			padding: 0;
			text-align: center;

			li {
				display: inline-block;

				&.active {
					.carousel-bullet {
						color: $green;
					}
				}
			}

			.carousel-bullet {
				color: $light-grey;
				cursor: pointer;
				display: block;
				font-size: 60px;

				&:hover {
					color: $dark-grey;
				}
			}
		}
	}

	@keyframes slidein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
</style>
