<svg width="164" height="100%" viewBox="0 0 164 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M146.118 63.6455C146.118 63.6455 118.239 63.6455 83.8475 63.6455C49.4563 63.6455 21.5768 63.6455 21.5768 63.6455C21.5768 63.6455 49.4563 63.6455 83.8475 63.6455C118.239 63.6455 146.118 63.6455 146.118 63.6455Z" stroke="white" stroke-width="0.265732"/>
<path d="M146.118 94.6113C146.118 94.6113 118.239 94.6113 83.8475 94.6113C49.4563 94.6113 21.5768 94.6113 21.5768 94.6113C21.5768 94.6113 49.4563 94.6113 83.8475 94.6113C118.239 94.6113 146.118 94.6113 146.118 94.6113Z" stroke="white" stroke-width="0.265732"/>
<path d="M90.9971 47.7388C90.515 49.6769 90.0294 51.6151 89.5473 53.5566C89.3387 53.5872 89.13 53.6178 88.9215 53.6519C88.4086 51.7988 87.8991 49.9489 87.3862 48.0959C87.2631 48.089 87.1368 48.0823 87.0137 48.0755C86.9726 48.6774 86.9007 49.2792 86.894 49.8845C86.8802 50.9011 86.9418 51.9212 86.8905 52.9344C86.8802 53.1521 86.5109 53.3527 86.3058 53.5601C86.1349 53.3764 85.8203 53.1894 85.8203 53.0057C85.8306 50.0102 85.8613 47.0112 85.9537 44.019C85.9605 43.7878 86.5623 43.339 86.7024 43.4002C87.0444 43.5498 87.4411 43.9034 87.5333 44.2503C87.9368 45.7804 88.2377 47.3343 88.5831 48.8779C88.7062 49.4254 88.8498 49.966 89.3831 50.5645C89.8276 48.5719 90.2722 46.5794 90.7098 44.5834C90.8944 43.7435 91.1167 42.9854 92.604 43.696C92.604 46.5317 92.6212 49.5172 92.5801 52.506C92.5766 52.8561 92.2279 53.203 92.0399 53.5498C91.821 53.2234 91.4278 52.9038 91.4142 52.5706C91.3457 50.9929 91.3834 49.4117 91.3834 47.8341C91.2534 47.8001 91.1269 47.7695 90.9971 47.7388Z" fill="white"/>
<path d="M114.139 53.7219C113.27 51.7701 112.398 49.815 111.53 47.8632C111.379 47.8667 111.229 47.8701 111.078 47.8735C111.078 49.3255 111.13 50.7773 111.048 52.2224C111.024 52.6781 110.651 53.1132 110.439 53.5552C110.23 53.1132 109.848 52.6712 109.841 52.2258C109.786 49.3084 109.813 46.3909 110.042 43.409C112.087 44.6705 111.95 47.173 113.739 48.7746C113.739 47.4349 113.691 46.0951 113.766 44.7589C113.793 44.293 114.128 43.8442 114.323 43.3887C114.525 43.8341 114.894 44.2761 114.901 44.7215C114.952 47.6355 114.929 50.5529 114.929 53.4702C114.665 53.5519 114.402 53.6369 114.139 53.7219Z" fill="white"/>
<path d="M84.7793 29.217C84.7793 30.0637 84.851 30.9205 84.7655 31.7604C84.5843 33.5456 83.6885 34.3683 82.0371 34.3854C80.3172 34.4024 79.3906 33.6714 79.2675 31.8556C79.141 29.9991 79.1819 28.1257 79.288 26.2657C79.3769 24.6982 80.4231 23.9977 81.9379 23.9705C83.4526 23.9433 84.4749 24.6438 84.704 26.1671C84.8544 27.1668 84.8169 28.197 84.8647 29.2136C84.8339 29.217 84.8066 29.217 84.7793 29.217ZM83.2815 29.1592C83.2815 28.2038 83.432 27.2109 83.2201 26.3032C83.1106 25.8372 82.3754 25.2864 81.8694 25.2253C81.5311 25.1844 80.7855 25.9154 80.7548 26.3404C80.6282 28.1903 80.6112 30.0637 80.7479 31.9134C80.7821 32.3896 81.5036 33.1511 81.931 33.1682C82.3653 33.1851 83.1072 32.5051 83.2201 32.0189C83.432 31.1076 83.2815 30.1181 83.2815 29.1592Z" fill="white"/>
<path d="M83.066 48.3046C83.066 49.2669 83.1549 50.2393 83.0455 51.188C82.8471 52.9153 81.8452 53.7449 80.2007 53.7042C78.6176 53.6633 77.6875 52.8813 77.5678 51.1812C77.4447 49.379 77.438 47.5566 77.5611 45.7578C77.6773 44.0272 78.7167 43.2077 80.3819 43.2485C81.9479 43.2858 82.8779 44.1122 83.0591 45.7647C83.1514 46.6045 83.0763 47.4613 83.0763 48.308C83.0728 48.3046 83.0694 48.3046 83.066 48.3046ZM78.9971 48.4099C78.9937 48.4099 78.9937 48.4099 78.9902 48.4099C78.9902 49.311 78.8296 50.2495 79.0483 51.0962C79.1886 51.6403 79.8793 52.0482 80.3238 52.5174C80.7581 52.0891 81.5342 51.6878 81.5684 51.2287C81.712 49.3825 81.7085 47.5157 81.5615 45.6694C81.5273 45.2275 80.7273 44.5304 80.2792 44.527C79.8554 44.5235 79.1749 45.224 79.0552 45.7204C78.8501 46.5774 78.9971 47.5123 78.9971 48.4099Z" fill="white"/>
<path d="M74.4436 47.1122C74.1735 46.7043 73.9307 46.2758 73.6332 45.8882C73.2435 45.385 72.8092 44.9191 72.392 44.4362C71.9818 44.9462 71.2672 45.4291 71.2158 45.9731C71.0654 47.6563 71.0654 49.37 71.2296 51.0497C71.2808 51.5666 72.0468 52.012 72.4878 52.4914C72.8674 52.0289 73.2674 51.5835 73.6197 51.1041C73.9307 50.6859 74.1941 50.2302 74.4778 49.7882C74.642 50.3118 74.9633 50.8389 74.9429 51.3558C74.8848 52.8042 74.0846 53.6509 72.5733 53.695C71.0484 53.7393 69.9507 53.049 69.8276 51.5189C69.6705 49.5536 69.6806 47.5645 69.7936 45.5958C69.8857 43.9807 70.9492 43.1781 72.5528 43.2462C74.1325 43.3142 74.9188 44.1813 75.001 45.7215C75.018 46.0581 75.0044 46.3948 75.0044 46.728C74.8163 46.8573 74.6317 46.9864 74.4436 47.1122Z" fill="white"/>
<path d="M47.1811 48.6087C47.1846 49.571 47.2838 50.5435 47.1572 51.4887C46.9829 52.808 46.2102 53.6615 44.7775 53.6921C43.3585 53.7227 42.3908 52.9576 42.2952 51.6281C42.1448 49.4963 42.1515 47.3371 42.3191 45.2052C42.4285 43.8315 43.5021 43.1888 44.8835 43.2602C46.2102 43.3316 47.0034 44.1239 47.1504 45.3888C47.2769 46.4461 47.1777 47.5343 47.1811 48.6087ZM45.8306 48.4456C45.8306 47.5411 45.9537 46.6128 45.7828 45.739C45.687 45.2595 45.1023 44.8719 44.7365 44.4434C44.35 44.8516 43.6594 45.2426 43.6286 45.6743C43.5055 47.5308 43.5055 49.4079 43.632 51.2644C43.6628 51.6927 44.3672 52.0736 44.7604 52.4747C45.116 52.0362 45.6904 51.6383 45.7828 51.1486C45.9502 50.2782 45.8306 49.3533 45.8306 48.4456Z" fill="white"/>
<path d="M62.8833 48.5789C62.8833 49.4832 62.9688 50.398 62.8661 51.2922C62.6849 52.8869 61.7207 53.7403 60.2983 53.6928C58.7699 53.6452 57.9699 52.7101 57.881 51.3296C57.7579 49.4119 57.7646 47.4771 57.881 45.5628C57.9665 44.1483 58.852 43.2677 60.3428 43.2541C61.8369 43.2405 62.6815 44.1076 62.8422 45.5221C62.955 46.5285 62.8627 47.5554 62.8627 48.5755C62.8696 48.5789 62.8765 48.5789 62.8833 48.5789ZM61.4745 48.5584C61.4779 48.5584 61.4779 48.5584 61.4814 48.5584C61.4814 47.5995 61.6045 46.6135 61.4301 45.6887C61.3412 45.2229 60.7087 44.8591 60.3256 44.4509C59.9666 44.825 59.3239 45.1855 59.2999 45.5765C59.1905 47.4908 59.1871 49.4188 59.2931 51.3331C59.3136 51.7342 59.9496 52.1015 60.3052 52.4822C60.6949 52.0777 61.3275 51.7241 61.4233 51.2582C61.6045 50.3946 61.4745 49.4629 61.4745 48.5584Z" fill="white"/>
<path d="M55.0273 48.5368C55.0273 49.4991 55.1299 50.4715 55.0034 51.4168C54.8153 52.8007 54.0357 53.7153 52.5074 53.6881C50.996 53.6609 50.1481 52.753 50.0523 51.3624C49.9225 49.4481 49.919 47.5133 50.0456 45.5956C50.1481 44.0248 51.2149 43.1678 52.6475 43.2461C54.1554 43.3277 54.9007 44.2457 55.0341 45.6466C55.1265 46.5987 55.0513 47.5677 55.0513 48.53C55.0444 48.5368 55.0341 48.5368 55.0273 48.5368ZM53.6356 48.4925C53.6425 48.4925 53.6494 48.4925 53.6562 48.4925C53.6562 47.5304 53.7622 46.5545 53.6083 45.6159C53.54 45.1842 52.9656 44.8373 52.6167 44.4497C52.2168 44.8407 51.4919 45.2114 51.4678 45.6228C51.3516 47.5371 51.3688 49.4651 51.4885 51.3759C51.5124 51.7636 52.1757 52.1139 52.5416 52.481C52.9075 52.1105 53.5159 51.7806 53.5878 51.359C53.7451 50.4342 53.6356 49.4548 53.6356 48.4925Z" fill="white"/>
<path d="M128.961 50.5185C129.908 51.3752 130.489 51.9737 131.163 52.4362C131.265 52.5075 132.117 51.875 132.076 51.7288C131.829 50.9162 131.535 50.0662 131.033 49.3963C130.458 48.6414 129.378 48.1858 129.009 47.3799C128.608 46.5061 128.441 45.2242 128.81 44.4049C129.084 43.7928 130.407 43.2998 131.204 43.3711C131.908 43.4358 132.606 44.2925 133.149 44.9353C133.313 45.129 132.944 45.7649 132.821 46.2002C132.65 46.1593 132.476 46.1185 132.305 46.0778C132.182 45.7751 132.144 45.3229 131.922 45.2005C131.399 44.9115 130.79 44.7788 130.216 44.5851C130.137 45.1868 129.785 45.9622 130.038 46.3566C130.544 47.1419 131.488 47.6385 132.052 48.4C132.667 49.2298 133.303 50.1784 133.488 51.161C133.751 52.5552 132.739 53.5582 131.354 53.6908C129.925 53.8268 128.684 52.9462 128.54 51.5724C128.496 51.1916 128.851 50.7734 128.961 50.5185Z" fill="white"/>
<path d="M106.845 53.7364C106.561 53.3487 106.189 52.9951 106.014 52.5668C105.737 51.8798 105.919 50.989 104.688 50.9787C103.494 50.9686 103.542 51.7575 103.303 52.5055C103.159 52.9578 102.776 53.3317 102.499 53.7431C102.277 53.6479 102.055 53.5528 101.833 53.461C102.031 52.451 102.222 51.4378 102.434 50.428C102.862 48.381 103.272 46.3273 103.758 44.294C103.843 43.9369 104.233 43.4711 104.551 43.4099C104.811 43.3624 105.385 43.7907 105.457 44.0933C106.178 47.1603 106.821 50.2477 107.488 53.3283C107.273 53.461 107.057 53.5969 106.845 53.7364ZM104.9 46.7727C104.715 46.7693 104.534 46.7693 104.349 46.7659C104.267 47.769 104.175 48.7686 104.158 49.7717C104.158 49.8092 104.886 49.8601 105.46 49.9282C105.242 48.7007 105.071 47.7384 104.9 46.7727Z" fill="white"/>
<path d="M125.745 48.5488C125.133 48.7018 124.525 48.8548 123.793 49.0385C123.793 50.1129 123.793 51.2077 123.793 52.289C124.06 52.3774 124.22 52.4216 124.374 52.4794C124.973 52.7072 125.571 52.9351 126.173 53.1663C125.506 53.3159 124.846 53.4961 124.176 53.6083C122.665 53.8599 122.046 53.3159 122.148 51.6905C122.292 49.4363 122.254 47.1649 122.159 44.9038C122.101 43.5879 122.617 43.1288 123.837 43.326C124.617 43.4518 125.386 43.6423 126.159 43.8054C125.472 44.1251 124.781 44.4447 123.913 44.8494C123.243 46.801 123.455 47.2567 125.745 48.5488Z" fill="white"/>
<path d="M95.4191 43.5483C98.6878 42.9839 100.028 43.47 100.445 45.2415C100.941 47.3428 100.151 48.2439 96.8689 49.1858C96.8689 50.2432 96.927 51.3619 96.8381 52.4704C96.8073 52.858 96.4141 53.215 96.1884 53.5857C95.932 53.2456 95.4635 52.9091 95.4568 52.5622C95.3952 49.5803 95.4191 46.5948 95.4191 43.5483ZM96.944 48.0093C97.7886 47.6421 98.6708 47.5333 98.9101 47.0845C99.2041 46.5336 99.194 45.5713 98.8862 45.0273C98.64 44.5955 97.7476 44.5275 96.944 44.2214C96.944 45.5747 96.944 46.5132 96.944 48.0093Z" fill="white"/>
<path d="M87.4945 24.318C90.9136 23.6687 92.2335 24.2228 92.5344 26.3173C92.8387 28.4255 91.9258 29.3334 88.9134 29.7039C88.9134 30.8976 88.9612 32.1317 88.8827 33.3525C88.862 33.6789 88.4552 33.9781 88.2262 34.2908C87.9834 33.995 87.5321 33.7027 87.5252 33.4035C87.474 30.3569 87.4945 27.3136 87.4945 24.318ZM89.0228 28.3881C90.4657 28.6839 91.1974 28.2044 91.2009 26.7901C91.2078 25.3381 90.4555 24.9062 89.0228 25.2701C89.0228 26.3004 89.0228 27.3408 89.0228 28.3881Z" fill="white"/>
<path d="M73.9562 25.3968C73.2485 25.1792 72.7219 25.0161 72.1953 24.8528C72.2159 24.6216 72.2363 24.387 72.2534 24.1557C73.7545 24.1557 75.259 24.1456 76.76 24.1796C76.8454 24.1829 77.0163 24.5501 76.9823 24.7134C76.9515 24.8731 76.7019 25.1214 76.5583 25.1146C75.2932 25.0533 75.4333 25.9341 75.4333 26.7195C75.4299 28.8106 75.471 30.9052 75.3991 32.9928C75.3855 33.435 74.9751 33.86 74.7495 34.2952C74.4861 33.8429 74.004 33.3975 73.9904 32.935C73.9151 30.4563 73.9562 27.9708 73.9562 25.3968Z" fill="white"/>
<path d="M117.885 48.2999C117.885 47.0009 117.83 45.6986 117.919 44.4066C117.943 44.0428 118.388 43.7095 118.637 43.3594C118.863 43.6789 119.28 43.9917 119.287 44.3148C119.338 47.0825 119.338 49.8503 119.287 52.6147C119.28 52.9377 118.866 53.2539 118.641 53.5736C118.388 53.2267 117.94 52.8934 117.919 52.533C117.834 51.1288 117.885 49.711 117.885 48.2999Z" fill="white"/>
<path d="M38.6718 42.9248C38.6718 46.5631 38.6925 49.5961 38.6411 52.6326C38.6343 52.9487 38.2172 53.2548 37.9915 53.5675C37.759 53.2616 37.3385 52.959 37.3247 52.6461C37.2563 50.8983 37.2905 49.1473 37.2905 47.3995C37.2905 46.7331 37.2905 46.0633 37.2905 45.3153C36.658 45.3153 36.169 45.3153 35.6767 45.3153C35.5913 45.152 35.5058 44.9889 35.4167 44.8256C36.3536 44.2781 37.2838 43.7341 38.6718 42.9248Z" fill="white"/>
<path d="M67.0792 109.241C68.016 109.241 68.7135 109.241 69.5479 109.241C69.5479 110.744 69.5479 112.227 69.5479 113.607C68.8435 113.706 68.2212 113.73 67.6331 113.893C66.1867 114.294 64.8123 113.692 64.672 112.203C64.4737 110.088 64.4669 107.922 64.6926 105.811C64.8566 104.27 66.238 104.015 67.5921 104.189C68.8504 104.349 69.4999 105.144 69.5034 106.385C69.5034 106.691 69.1991 106.997 69.0316 107.303C68.7854 107.065 68.5426 106.827 68.2964 106.593C67.8315 106.147 67.3629 105.709 66.8946 105.263C66.5321 105.824 65.9235 106.358 65.8585 106.95C65.7046 108.344 65.7046 109.779 65.8585 111.176C65.9235 111.775 66.5321 112.312 66.8912 112.876C67.3595 112.56 68.0741 112.339 68.2417 111.911C68.5187 111.2 68.8162 110.207 67.4929 109.966C67.3903 109.942 67.3287 109.694 67.0792 109.241Z" fill="white"/>
<path d="M83.2241 109.004C83.2241 109.796 83.2753 110.592 83.2137 111.377C83.0667 113.217 82.2222 114.009 80.5365 113.975C78.9466 113.941 78.0816 113.131 77.996 111.408C77.9174 109.83 77.9174 108.242 77.996 106.661C78.0816 104.974 78.9944 104.152 80.5878 104.138C82.1675 104.124 83.0566 104.937 83.2036 106.634C83.2719 107.419 83.2137 108.215 83.2137 109.007C83.2206 109.004 83.2241 109.004 83.2241 109.004ZM79.3945 108.854C79.4014 108.854 79.4083 108.854 79.4116 108.854C79.4116 109.871 79.3022 110.901 79.4664 111.89C79.5279 112.271 80.2049 112.802 80.6118 112.808C80.9982 112.815 81.7093 112.247 81.7332 111.901C81.8496 110.047 81.8563 108.177 81.7504 106.321C81.7299 105.943 81.0186 105.28 80.7042 105.321C80.2322 105.386 79.562 105.889 79.4526 106.328C79.251 107.127 79.3945 108.011 79.3945 108.854Z" fill="white"/>
<path d="M85.9326 104.303C87.5261 104.293 89.3108 103.65 90.1896 105.275C90.6682 106.163 90.275 107.516 90.275 109.172C90.9897 109.835 91.3111 111.263 90.5144 112.688C89.4853 114.53 87.649 113.772 85.936 113.854C85.9326 110.675 85.9326 107.594 85.9326 104.303ZM87.2765 109.056C87.2765 110.566 87.2765 111.657 87.2765 113.123C88.1004 112.779 89.0237 112.677 89.2425 112.232C89.54 111.623 89.5194 110.648 89.2185 110.032C88.9929 109.58 88.1038 109.444 87.2765 109.056ZM87.3243 105.401C87.3243 106.401 87.3243 107.251 87.3243 108.084C88.6304 108.349 89.3655 108.036 89.3724 106.713C89.3758 105.309 88.5142 105.204 87.3243 105.401Z" fill="white"/>
<path d="M78.6102 128.299C78.6102 127.395 78.5418 126.48 78.6272 125.586C78.7504 124.3 79.4582 123.498 80.8292 123.444C82.1731 123.389 83.1304 124.042 83.2124 125.351C83.3424 127.374 83.3288 129.418 83.1784 131.437C83.086 132.651 82.1765 133.311 80.9045 133.277C79.554 133.24 78.7846 132.475 78.6444 131.186C78.5418 130.234 78.624 129.265 78.624 128.302C78.6205 128.299 78.6137 128.299 78.6102 128.299ZM82.0054 128.456C82.002 128.456 82.0022 128.456 81.9987 128.456C81.9987 127.5 82.1081 126.524 81.9473 125.596C81.8756 125.188 81.2601 124.868 80.8908 124.511C80.5729 124.851 79.995 125.181 79.978 125.535C79.8822 127.381 79.8685 129.241 79.9745 131.087C79.995 131.468 80.6413 131.818 81.0003 132.179C81.3354 131.781 81.8722 131.421 81.9611 130.975C82.1184 130.162 82.0054 129.299 82.0054 128.456Z" fill="white"/>
<path d="M97.6044 114.009C97.3173 113.652 97.0302 113.291 96.7394 112.938C96.3361 112.451 96.0146 111.72 95.5017 111.547C94.4793 111.2 94.435 112.149 94.1956 112.842C94.0452 113.281 93.6724 113.641 93.3989 114.036C93.1938 113.941 92.9921 113.842 92.7868 113.747C93.4159 110.795 94.0314 107.841 94.7051 104.899C94.7666 104.634 95.3273 104.23 95.4881 104.298C95.8299 104.44 96.23 104.808 96.3086 105.151C96.9514 107.949 97.5259 110.765 98.1208 113.577C97.9499 113.716 97.7755 113.862 97.6044 114.009ZM94.7393 110.197C95.105 110.296 95.4709 110.391 95.8368 110.489C96.4693 109.534 95.7479 108.34 95.2862 107.181C95.1085 108.184 94.9238 109.191 94.7393 110.197Z" fill="white"/>
<path d="M87.4524 131.931C87.9961 132.012 88.2253 132.016 88.4338 132.084C89.0459 132.281 89.6512 132.502 90.2596 132.716C89.6066 132.883 88.9638 133.124 88.3039 133.196C87.5312 133.281 86.7448 133.216 85.6301 133.216C85.9105 132.43 86.0233 131.852 86.3037 131.376C87.0217 130.162 87.8697 129.02 88.5535 127.789C88.8989 127.174 89.128 126.429 89.1622 125.732C89.1826 125.355 88.6835 124.947 88.4201 124.556C88.0202 124.868 87.5824 125.14 87.2303 125.501C86.8918 125.848 86.6387 126.28 86.3481 126.671C86.078 126.494 85.8079 126.314 85.5412 126.137C86.0541 125.314 86.3926 124.212 87.1345 123.753C87.726 123.386 89.1895 123.485 89.5862 123.947C90.1776 124.641 90.5366 125.923 90.3315 126.8C90.0476 128.007 89.145 129.071 88.4988 130.19C88.198 130.707 87.8799 131.22 87.4524 131.931Z" fill="white"/>
<path d="M71.0713 133.21C71.6354 132.145 71.9705 131.472 72.3398 130.819C73.1741 129.35 74.1281 127.936 74.8016 126.395C74.9931 125.96 74.3811 125.178 74.1383 124.556C73.7109 124.971 73.287 125.389 72.8527 125.797C72.5415 126.09 72.2167 126.368 71.8988 126.654C71.6765 126.481 71.4542 126.307 71.232 126.134C71.7449 125.307 72.0766 124.155 72.8219 123.75C73.475 123.393 74.976 123.549 75.4034 124.06C75.9744 124.743 76.2822 126.137 75.9744 126.953C75.3317 128.657 74.2136 130.183 73.1775 131.975C73.8785 132.02 74.4357 131.989 74.9521 132.111C75.3076 132.196 75.6085 132.496 75.9369 132.699C75.6326 132.87 75.3351 133.165 75.0239 133.182C73.9024 133.244 72.7741 133.21 71.0713 133.21Z" fill="white"/>
<path d="M104.167 113.691C102.994 113.691 101.818 113.691 100.368 113.691C100.368 110.818 100.354 107.853 100.395 104.892C100.399 104.674 100.799 104.463 101.017 104.249C101.257 104.558 101.695 104.858 101.705 105.177C101.773 107.034 101.818 108.901 101.715 110.754C101.633 112.226 101.879 113.198 103.667 112.95C103.832 112.926 104.023 113.13 104.204 113.229C104.191 113.386 104.18 113.538 104.167 113.691Z" fill="white"/>
<path d="M95.472 123.11C95.472 126.555 95.4858 129.52 95.4481 132.481C95.4447 132.713 95.0891 132.94 94.8976 133.168C94.6582 132.862 94.2241 132.56 94.2138 132.243C94.1522 130.054 94.183 127.857 94.183 125.555C93.6017 125.467 93.1333 125.392 92.6648 125.321C92.5794 125.174 92.4905 125.028 92.405 124.882C93.2907 124.368 94.1796 123.855 95.472 123.11Z" fill="white"/>
<path d="M72.2256 113.676C72.2256 110.789 72.2084 107.923 72.253 105.06C72.2564 104.791 72.6632 104.533 72.8786 104.268C73.1111 104.567 73.5351 104.859 73.5454 105.169C73.6069 107.086 73.5796 109.004 73.5796 110.922C73.5796 111.527 73.5796 112.132 73.5796 112.693C74.5473 112.877 75.296 113.016 76.0448 113.159C76.0278 113.332 76.0106 113.503 75.9936 113.676C74.8344 113.676 73.6719 113.676 72.2256 113.676Z" fill="white"/>
<path d="M113.745 87.1074C112.733 87.1074 112.005 87.1074 110.958 87.1074C110.958 85.2918 110.972 83.51 110.955 81.7284C110.934 79.5555 110.428 78.8178 108.992 78.7803C107.207 78.7328 106.26 79.7664 106.216 81.8643C106.182 83.4488 106.189 85.0333 106.164 86.6145C106.164 86.7164 106.093 86.8219 106.004 87.0496C105.238 87.0496 104.428 87.0496 103.528 87.0496C103.528 81.6093 103.528 76.2642 103.528 70.834C104.414 70.834 105.19 70.834 106.178 70.834C106.178 72.7755 106.178 74.6694 106.178 76.4987C106.493 76.5974 106.606 76.679 106.691 76.6552C111.519 75.3563 113.714 77.0291 113.735 82.0377C113.748 83.6664 113.745 85.2952 113.745 87.1074Z" fill="white"/>
<path d="M66.4912 74.1243C65.8688 74.7125 65.3423 75.209 64.8329 75.6919C60.9965 73.2879 58.2371 73.3014 56.2266 75.685C54.4658 77.7761 54.5033 81.1899 56.3121 83.1995C58.3877 85.5014 61.335 85.4674 64.8088 83.0396C65.3081 83.5973 65.8175 84.1686 66.327 84.7364C63.5779 88.1399 58.1038 88.4663 54.7769 85.4198C51.4535 82.3767 51.3748 76.6846 54.6128 73.4987C57.8199 70.3432 63.3078 70.5643 66.4912 74.1243Z" fill="white"/>
<path d="M73.2621 76.4158C73.9972 76.4158 74.6504 76.4158 75.5393 76.4158C75.5393 78.4084 75.5496 80.3056 75.5358 82.2064C75.5257 83.6344 75.8094 84.8484 77.5464 84.9911C79.2491 85.1305 80.5108 84.0663 80.593 82.2982C80.6784 80.3873 80.6134 78.473 80.6134 76.6028C83.2188 75.9567 83.3761 76.0791 83.3761 78.5546C83.3761 81.3222 83.3761 84.0866 83.3761 86.902C81.2904 87.14 79.3073 87.5311 77.3241 87.5447C75.1803 87.5617 73.5118 86.1711 73.3168 84.0832C73.0844 81.5807 73.2621 79.0408 73.2621 76.4158Z" fill="white"/>
<path d="M102.199 78.0191C101.679 78.512 101.156 79.0085 100.575 79.5593C100.004 79.2872 99.4734 78.9745 98.9024 78.7705C97.4219 78.2366 95.685 78.7126 94.9259 79.8959C94.1053 81.1744 94.1087 82.5141 95.0832 83.6871C96.1398 84.9588 97.6647 85.4383 99.0666 84.6052C100.592 83.7008 101.32 84.3401 102.076 85.4689C100.39 87.883 96.3415 88.2333 93.7669 86.1728C91.2332 84.1428 90.9425 80.1883 93.1548 77.8354C95.5141 75.3193 99.7573 75.3566 102.199 78.0191Z" fill="white"/>
<path d="M84.4675 78.3489C84.4675 77.6586 84.4675 77.1111 84.4675 76.4821C87.3909 76.2747 85.4317 73.8673 86.4062 72.5752C87.1345 72.5752 88.0131 72.5752 89.0527 72.5752C89.1245 73.7517 89.1962 74.9111 89.2646 76.0673C89.3877 75.9789 89.5074 75.8871 89.6305 75.7987C90.1604 76.3393 90.6905 76.8798 91.2238 77.4172C90.7041 77.9033 90.1843 78.3862 89.6647 78.8724C89.521 78.7806 89.3774 78.6922 89.2339 78.6004C89.2339 81.3511 89.2339 84.102 89.2339 86.9955C88.1362 86.9955 87.2165 86.9955 86.0881 86.9955C86.0881 84.7752 86.0369 82.582 86.1088 80.3957C86.1497 79.1954 86.0677 78.2297 84.4675 78.3489Z" fill="white"/>
<path d="M70.6786 87.0755C69.694 87.0755 68.8563 87.0755 67.8544 87.0755C67.8544 81.71 67.8544 76.3683 67.8544 70.8838C68.7399 70.8838 69.6563 70.8838 70.6786 70.8838C70.6786 76.2528 70.6786 81.5502 70.6786 87.0755Z" fill="white"/>
<path d="M97.0011 79.8672C98.7244 79.9794 99.521 80.4962 99.521 81.744C99.521 82.8865 98.803 83.5258 97.7054 83.5496C96.536 83.5734 95.6095 82.9375 95.7873 81.7372C95.8968 80.9892 96.6763 80.3398 97.0011 79.8672Z" fill="white"/>
<path d="M81.9928 149C123.09 149 156.406 115.869 156.406 75C156.406 34.1309 123.09 1 81.9928 1C40.8954 1 7.57935 34.1309 7.57935 75C7.57935 115.869 40.8954 149 81.9928 149Z" stroke="white" stroke-width="2"/>
<path d="M155.686 63.6455H163.99V94.6079H153.61C153.61 94.6079 155.282 85.1725 155.686 79.1268C156.091 73.0809 155.686 63.6455 155.686 63.6455Z" fill="white"/>
<path d="M0.010376 63.6455H8.31317L9.35097 94.6079H0.010376V63.6455Z" fill="white"/>
</svg>
